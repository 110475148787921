import { useContext, useState } from 'react';
import { UserContext } from "../context/user-context";
import {useNavigate, useParams} from 'react-router-dom';
import { getUpdate } from '../utils/firebase';
import { useEffect } from 'react';
import Button from '../components/button';
import { format } from "date-fns";

const defaultFormFields = {
    title: "",
    article: "",
    imageFileUrl: "",
}

const Update = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { title, article, imageFileUrl, created } = formFields;

    useEffect(() => {
        const get = async () => {
            const doc = await getUpdate(id);
            setFormFields(doc);
        }
        get();
    }, []);

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold mt-6'>{title}
                    {currentUser.data.type === "superadmin" ?
                    <Button additionalClasses="block is-pulled-right my-3" type="button" onClick={() => {navigate("/news-updates/" + id + "/update")}}>Update</Button>
                    : ""}</h2>
                    <time className='mb-6 is-block'>{created ? format(created.toDate(), "MMMM dd, yyyy") : ""}</time>

                        <div className="block pb-6">
                        <figure className="image is-4by3">
                        <img src={imageFileUrl} alt="image"/>
                        </figure>

                        <p className='mt-5'>{article}</p>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Update;