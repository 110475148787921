import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import Button from '../components/button';
import ContractItem from '../components/contract-item';
import { getContractDocuments } from '../utils/firebase';
import {useNavigate} from 'react-router-dom';

const Contracts = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [contracts, setContracts = () => []] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const docs = await getContractDocuments();
            setContracts(docs);
        }
        getUsers();
    }, []);

    const onClickHandler = () => {
        navigate("/contracts/new")
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Contracts
                        <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onClickHandler}>Create New</Button></h2>
                        <div className='block pb-6'>
                        {contracts.map((contract) => {
                                return <ContractItem key={contract.id} contract={contract}/>
                            })
                        }
                        {contracts.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold'>Empty</p><p>No contracts</p></div> : ""}
                        
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default Contracts;