import {useNavigate} from 'react-router-dom';
import { format } from "date-fns";
import Button from './button';

const UpdateItem = ({update, additionalClasses}) => {
    const { title, article, imageFileUrl, id, created } = update;
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate('/news-updates/' + id);
    }

    return (
        <div className={additionalClasses}>
        <div className="card">
            <div className="card-image">
                <figure className="image is-4by3">
                <img src={imageFileUrl} alt="image"/>
                </figure>
            </div>
            <div className="card-content">
                <div className="media">
                <div className="media-content">
                    <p className="title is-4 has-text-weight-bold mb-1">{title}</p>
                    <time>{format(created.toDate(), "MMMM dd, yyyy")}</time>
                </div>
                </div>

                <div className="content">
                {article.length > 100 ? article.substring(0, 100) + "..." : article}

                <Button type="button" additionalClasses="is-block is-fullwidth mt-5" onClick={onClickHandler}>Read More</Button>
                </div>
            </div>
        </div>
        </div>
    )
}

export default UpdateItem;