import { useState, useContext } from 'react';
import FormInput from '../../../components/form-input';
import Button from '../../../components/button';
import {useNavigate} from 'react-router-dom';
import { UserContext } from "../../../context/user-context";
import { createLog, updateUserPassword } from '../../../utils/firebase';

const ChangePassword = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password.length < 6) {
            alert("Password must be at least 6 characters in length");
            return;
        } else if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const res = await updateUserPassword(password);
        if (res === "success") {
            const log = {"action": "User updated password", "by" : currentUser.uid}
            await createLog(log);

            setPassword("");
            setConfirmPassword("");

            alert("Password has been updated successfully");
            return;
        }
        
        alert(res);
    }

    return (
        <div className='column mt-6'>
            <div className='columns mt-6'>
                <div className='column is-4 is-offset-4'>
                    <h2 className='is-size-2 has-text-weight-bold'>Change Password</h2>
                    <p className='block'>Type in a new password.</p>
                    <form onSubmit={handleSubmit}>
                        <FormInput type="password" required id="password" value={password} onChange={(e) => {setPassword(e.target.value)}} label="Password" additionalClasses="block"/>
                        <FormInput type="password" required id="confirmPassword" value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} label="Confirm Password" additionalClasses="block"/>
                        <Button type="submit" additionalClasses="is-fullwidth is-success block">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;