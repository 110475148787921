import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { NotificationsContext } from '../context/notifications-context';
import NotificationItem from '../components/notification-item';
import { OfficesContext } from '../context/offices-context';

const Notifications = () => {
    const { notifications } = useContext(NotificationsContext);
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Notifications</h2>
                        <div className="block pb-6">
                            {notifications.map((noti, index) => {
                                    const pos = offices.map(a => a.id).indexOf(noti.oid);
                                    return <NotificationItem office={pos > -1 ? offices[pos] : null} notification={noti} key={index} currentUser={currentUser}/>
                                })
                            }

                            {notifications.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold'>Empty</p><p>No notifications</p></div> : ""}
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Notifications;