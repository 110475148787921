import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { createLog, updateUserDocument } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';
import FormSelect from '../../components/form-select';

const WorkExperience = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState({work: currentUser["data"]["work"] ? currentUser["data"]["work"] : []});
    const navigate = useNavigate();
    const { work } = formFields;

    const handleChange = (index, event) => {
        const { id, value } = event.target;
        work[index][id] = value;

        setFormFields({...formFields});
    }

    const handleOnAdd = () => {
        work.push({
            "from" : "",
            "to" : "",
            "position": "",
            "department": "",
            "salary": "",
            "grade": "",
            "status": "",
            "goverment": "Select"
        });

        setFormFields({...formFields});
    }

    const handleOnRemove = (index) => {
        work.splice(index, 1);

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await updateUserDocument(currentUser["uid"], formFields);
        if (response === "success") {
            const log = {"action": "User updated PDS work experience", "by" : currentUser.uid}
            await createLog(log);

            Object.assign(currentUser["data"], formFields);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Work Experience</h2>
                    <form onSubmit={handleSubmit}>
                        {work.map((exp, index) => {
                            return (
                                <div className='block' key={index}>
                                    <div className='columns is-vcentered'>
                                        <FormInput type="date" required id="from" value={exp["from"]} onChange={(e) => handleChange(index, e)} label="From" additionalClasses="column is-5"/>
                                        <FormInput type="date" required id="to" value={exp["to"]} onChange={(e) => handleChange(index, e)} label="To" additionalClasses="column is-5"/>
                                        <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(index)} className="delete is-large"></button></div>
                                    </div>
                                    
                                    <div className='columns'>
                                        <FormInput type="text" required id="position" value={exp["position"]} onChange={(e) => handleChange(index, e)} label="Position Title" additionalClasses="column is-5"/>
                                        <FormInput type="text" required id="department" value={exp["department"]} onChange={(e) => handleChange(index, e)} label="Department/Agency/Office/Company" additionalClasses="column is-5"/>
                                    </div>
                                    <div className='columns'>
                                        <FormInput type="number" id="salary" value={exp["salary"]} onChange={(e) => handleChange(index, e)} label="Monthly Salary" additionalClasses="column is-5"/>
                                        <FormInput type="text" id="grade" value={exp["grade"]} onChange={(e) => handleChange(index, e)} label="Salary/Job/Pay Grade & Step" additionalClasses="column is-5"/>
                                    </div>
                                    
                                    <div className='columns'>
                                        <FormInput type="text" required id="status" value={exp["status"]} onChange={(e) => handleChange(index, e)} label="Status of Appointment" additionalClasses="column is-5"/>
                                        <FormSelect options={["Yes", "No"]} type="text" required id="goverment" onChange={(e) => handleChange(index, e)} value={exp["goverment"]} label="Goverment Service" additionalClasses="column is-5"/>
                                    </div>
                                </div>
                            )
                        })}
                        <Button additionalClasses="block mr-4" type="button" onClick={handleOnAdd}>Add Work Experience</Button>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default WorkExperience;