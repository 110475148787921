import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import Button from "./button";
import {useNavigate} from 'react-router-dom';
TimeAgo.addLocale(en)

const ApplicantItem = ({user, onClickHandler, office}) => {
    const { firstName, surname, middleName, emailAddress, created, employment, id } = user;
    const navigate = useNavigate();

    const onClick = () => {
        navigate('/pds/' + id);
    }

    return (
        <article className="media with-background-white">
            <figure onClick={onClick} className="media-left clickable">
                <p className="image is-64x64">
                <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png"/>
                </p>
            </figure>
            <div onClick={onClick} className="media-content clickable">
                <div className="content">
                <p>
                <strong>{middleName ? surname + ", " + firstName + " " + middleName.substring(0, 1) + "." : surname + ", " + firstName}</strong>
                <small> <ReactTimeAgo date={created.toDate()} locale="en-US"/></small>
                    <br/>
                    Office: {office ? office.name : "N/A"}
                    <br/>
                    Employment: {employment}
                </p>
                </div>
            </div>
            <div className="media-right">
                <Button type="button" additionalClasses="mr-2" onClick={() => {onClickHandler(true, user)}}>Approve</Button>
                {/* <Button type="button" onClick={() => {onClickHandler(false, user)}}>Disapprove</Button> */}
            </div>
        </article>
    )
}

export default ApplicantItem;