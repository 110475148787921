import { useContext, useState } from 'react';
import { UserContext } from "../context/user-context";
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import { createContract, searchContractUsers, updateUserContract, uploadContract } from '../utils/firebase';
import FormSelect from '../components/form-select';
import { Fragment } from 'react';
import SearchItem from '../components/search-item';

const defaultFormFields = {
    list: [],
    file: null
}

const NewContract = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const result = offices.map(a => a.name);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { list, file } = formFields;
    const [participants, setParticipants] = useState([]);

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = async(index, event) => {
        const { id, value } = event.target;
        list[index][id] = value;

        if (id === "search") {
            const s = value.toLowerCase().trim().split(" ");
            const docs = await searchContractUsers(s, participants);
            list[index]["users"] = docs;
        }

        setFormFields({...formFields});
    }

    const handleUpload = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }
        setFormFields({...formFields, "file": files[0]});
    }

    const handleOnAdd = () => {
        list.push({
            'designation': '',
            'rate': '',
            'from': '',
            'to': '',
            'funding': '',
            'office': 'Select',
            'user': null,
            'users': []
        });

        setFormFields({...formFields});
    }

    const handleOnRemove = (index) => {
        if (list[index]["user"] !== null) {
            setParticipants(participants.filter(user => user.id !== list[index]["user"].id));
        }

        list.splice(index, 1);

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (list.length === 0) {
            alert("Add a user");
            return;
        }

        const pos = list.map(a => a.user).indexOf(null) + 1;
        if (pos > 0) {
            alert("Select a name or remove user at position " + pos);
            return;
        }

        const up = await uploadContract(file);
        if (up.includes("error")) {
            alert(up);
            return;
        }

        const resp = await createContract({list, "url": up, "fileName": file.name})
        if (resp.includes("error")) {
            alert(resp);
            return;
        }

        const res = await updateUserContract(resp, list);
        if (res === "success") {
            navigate("/contracts/" + resp);
            return;
        }

        alert(res);
    }

    const onAddHandler = async(index, user) => {
        list[index]["users"] = [];
        list[index]["user"] = user;
        list[index]["search"] = "";

        setFormFields({...formFields});

        const pos = participants.map(a => a.id).indexOf(user.id);
        if (pos !== -1) {
            return;
        }
        setParticipants([...participants, user]);
    }

    const onRemoveHandler = async(index, userToRemove) => {
        list[index]["users"] = [];
        list[index]["user"] = null;
        list[index]["search"] = "";

        setFormFields({...formFields});
        setParticipants(participants.filter(user => user.id !== userToRemove.id));
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Contract</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                <div className='columns'>
                                    <div className='column is-12'>
                                        <label>File Upload</label>
                                        <div className="file has-name is-fullwidth">
                                            <label className="file-label">
                                                <input required className="file-input" type="file" onChange={handleUpload} id='contract'
                                                accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                                                <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    Choose a file…
                                                </span>
                                                </span>
                                                <span className="file-name">
                                                    {file ? file.name : ""}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                    {list.map((item, index) => {
                                        const { designation, rate, from, to, funding, office, user, users, search } = item;
                                        
                                        return (
                                            <div className='columns is-vcentered mb-6' key={index}>
                                            <div className='column is-11'>
                                                <div className='columns is-multiline' >
                                                    <div className='custom-dropdown column is-6'>
                                                        <label>Name</label>
                                                        <div className="custom-input is-only">
                                                            {user ? <SearchItem key={user.id} user={user} buttonText="Remove" index={index} onClickHandler={onRemoveHandler}/> : ""}
                                                            <input disabled={user !== null} autoComplete="off" type="text" id="search" value={search} onChange={(e) => handleChange(index, e)}/>
                                                        </div>
                                                        {search ? users.length > 0 ?
                                                            <div className="custom-dropdown-menu">
                                                                <div className='custom-dropdown-content'>
                                                                {users.map((user, i) => {
                                                                    const pos = offices.map(a => a.id).indexOf(user.oid);
                                                                        return <SearchItem tabIndex={i} key={user.id} index={index} office={pos > -1 ? offices[pos] : null} user={user} buttonText="Add" onClickHandler={onAddHandler} additionalClasses="column is-12"/>
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                            : 
                                                            <div className="custom-dropdown-menu">
                                                                <div className='custom-dropdown-content has-text-centered py-5'>
                                                                    No results found
                                                                    </div>
                                                                </div>
                                                        : ""}
                                                    </div>
                                                    <FormInput type="text" required id="designation" value={designation} onChange={(e) => handleChange(index, e)} label="Designation" additionalClasses="column is-6"/>
                                                    <FormInput type="date" required id="from" value={from} onChange={(e) => handleChange(index, e)} label="Period of Employment (FROM)" additionalClasses="column is-6"/>
                                                    <FormInput type="date" required id="to" value={to} onChange={(e) => handleChange(index, e)} label="Period of Employment (TO)" additionalClasses="column is-6"/>
                                                    <FormInput type="number" step="0.01" required id="rate" value={rate} onChange={(e) => handleChange(index, e)} label="Rate per Day" additionalClasses="column is-6"/>
                                                    <FormInput type="text" required id="funding" value={funding} onChange={(e) => handleChange(index, e)} label="Funding Charges" additionalClasses="column is-6"/>
                                                    <FormSelect options={result} type="text" required id="office" value={office} onChange={(e) => handleChange(index, e)} label="Office" additionalClasses="column is-6"/>
                                                </div>
                                                </div>
                                                <div className='column is-1 has-text-centered'>
                                                    <button className="delete" onClick={() => handleOnRemove(index)}></button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Button additionalClasses="block mr-4" type="button" onClick={handleOnAdd}>Add User</Button>
                                    <Button additionalClasses="block is-success" type="submit">Submit</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewContract;