import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { createLog, updateUserDocument } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const defaultSpouse = {
    "surname" : "",
    "firstName" : "",
    "middleName" : "",
    "nameExtension" : "",
    "occupation" : "",
    "businessName": "",
    "businessAddress": "",
    "telephoneNumber": ""
}

const defaultParents = {
    "surname" : "",
    "firstName" : "",
    "middleName" : "",
    "nameExtension" : "",
}

const FamilyBackground = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState({children: currentUser["data"]["children"] ? currentUser["data"]["children"] : [], spouse : currentUser["data"]["spouse"] ? currentUser["data"]["spouse"] : defaultSpouse,
    mother: currentUser["data"]["mother"] ? currentUser["data"]["mother"] : defaultParents, father: currentUser["data"]["father"] ? currentUser["data"]["father"] : defaultParents});
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const { spouse, mother, father, children } = formFields;

    const handleChange = (object, event) => {
        const { id, value } = event.target;
        object[id] = value;

        setFormFields({...formFields});
    }

    const handleChildChange = (index, event) => {
        const { id, value } = event.target;
        children[index][id] = value;

        setFormFields({...formFields});
    }

    const handleOnAdd = () => {
        children.push({
            "fullName" : "",
            "birthDate" : ""
        });

        setFormFields({...formFields});
    }

    const handleOnRemove = (index) => {
        children.splice(index, 1);

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await updateUserDocument(currentUser["uid"], formFields);
        if (response === "success") {
            const log = {"action": "User updated PDS family background", "by" : currentUser.uid}
            await createLog(log);

            Object.assign(currentUser["data"], formFields);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Family Background</h2>
                    <Tabs className="block" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="tabs is-toggle">
        <TabList>
            <Tab className={tabIndex === 0 ? "is-active" : ""}><a>Spouse</a></Tab>
            <Tab className={tabIndex === 1 ? "is-active" : ""}><a>Father</a></Tab>
            <Tab className={tabIndex === 2 ? "is-active" : ""}><a>Mother</a></Tab>
            <Tab className={tabIndex === 3 ? "is-active" : ""}><a>Children</a></Tab>
            </TabList>
        </div>
            <TabPanel>
                    <form onSubmit={handleSubmit}>
                <div className='columns'>
            <FormInput type="text" required id="surname" value={spouse["surname"]} onChange={(e) => handleChange(spouse, e)} label="Surname" additionalClasses="column is-6"/>
                <FormInput type="text" required id="firstName" value={spouse["firstName"]} onChange={(e) => handleChange(spouse, e)} label="First Name" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
            <FormInput type="text" required id="middleName" value={spouse["middleName"]} onChange={(e) => handleChange(spouse, e)} label="Middle Name" additionalClasses="column is-6"/>
                <FormInput type="text" id="nameExtension" value={spouse["nameExtension"]} onChange={(e) => handleChange(spouse, e)} label="Name Extension" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
            <FormInput type="text" required id="businessName" value={spouse["businessName"]} onChange={(e) => handleChange(spouse, e)} label="Employer/Business Name" additionalClasses="column is-6"/>
                <FormInput type="text" required id="businessAddress" value={spouse["businessAddress"]} onChange={(e) => handleChange(spouse, e)} label="Business Address" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
            <FormInput type="text" required id="occupation" value={spouse["occupation"]} onChange={(e) => handleChange(spouse, e)} label="Occupation" additionalClasses="column is-6"/>
                <FormInput type="tel" required id="telephoneNumber" value={spouse["telephoneNumber"]} onChange={(e) => handleChange(spouse, e)} label="Telephone No." additionalClasses="column is-6"/>
            </div>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
        </TabPanel>

        <TabPanel>
                    <form onSubmit={handleSubmit}>
                <div className='columns'>
            <FormInput type="text" required id="surname" value={father["surname"]} onChange={(e) => handleChange(father, e)} label="Surname" additionalClasses="column is-6"/>
                <FormInput type="text" required id="firstName" value={father["firstName"]} onChange={(e) => handleChange(father, e)} label="First Name" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
            <FormInput type="text" required id="middleName" value={father["middleName"]} onChange={(e) => handleChange(father, e)} label="Middle Name" additionalClasses="column is-6"/>
                <FormInput type="text" id="nameExtension" value={father["nameExtension"]} onChange={(e) => handleChange(father, e)} label="Name Extension" additionalClasses="column is-6"/>
            </div>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
        </TabPanel>

        <TabPanel>
                    <form onSubmit={handleSubmit}>
                <div className='columns'>
            <FormInput type="text" required id="surname" value={mother["surname"]} onChange={(e) => handleChange(mother, e)} label="Maiden Name" additionalClasses="column is-6"/>
                <FormInput type="text" required id="firstName" value={mother["firstName"]} onChange={(e) => handleChange(mother, e)} label="First Name" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
            <FormInput type="text" required id="middleName" value={mother["middleName"]} onChange={(e) => handleChange(mother, e)} label="Middle Name" additionalClasses="column is-6"/>
            </div>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
        </TabPanel>

        <TabPanel>
        <form onSubmit={handleSubmit}>
            {children.map((child, index) => {
                return (
                    <div className='columns is-vcentered' key={index}>
                    <FormInput type="text" required id="fullName" value={child["fullName"]} onChange={(e) => handleChildChange(index, e)} label="Full Name" additionalClasses="column is-5"/>
                    <FormInput type="date" required id="birthDate" value={child["birthDate"]} onChange={(e) => handleChildChange(index, e)} label="Date of Birth" additionalClasses="column is-5"/>
                    <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(index)} className="delete is-large"></button></div>
                </div>
                )
            })}
            
        <Button additionalClasses="block mr-4" type="button" onClick={handleOnAdd}>Add Child</Button>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
        </TabPanel>
        </Tabs>
        
                </div>
            </div>
        </div>
    )
}

export default FamilyBackground;