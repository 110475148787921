import { useContext } from 'react';
import { UserContext } from "../context/user-context";
import { getUpdates } from '../utils/firebase';
import UpdateItem from '../components/update-item';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button'

const Dashboard = () => {
    const { currentUser } = useContext(UserContext);
    const [updates, setUpdates = () => []] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const get = async () => {
            var docs = await getUpdates();
            setUpdates(docs);
        }
        get();
    }, []);

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>{currentUser.data.type === "user" ? "Home" : "News & Updates"}
                    {currentUser.data.type === "superadmin" ?
                    <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={() => {navigate("/news-updates/new")}}>Create Article</Button>
                    : ""}</h2>
                    <div className="block pb-6">
                        <div className='columns is-multiline'>
                        {updates.map((update) => {
                                    return <UpdateItem key={update.id} update={update} additionalClasses="column is-6"/>
                                })
                            }
                        </div>
                            {updates.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold '>Empty</p><p>No news & updates</p></div> : ""}
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Dashboard;