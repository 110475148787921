import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import FormTextArea from '../components/form-textarea';
import { createLeaveApplication, createLog, createTravelOrder } from '../utils/firebase';
import { searchUsers } from '../utils/firebase';
import SearchItem from '../components/search-item';
import FormSelect from '../components/form-select';
import { Fragment } from 'react';

const defaultFormFields = {
    type: 'Select',
    details: 'Select',
    specify: '',
    from: '',
    to: '',
    status: 'For HR Approval',
    commutation: 'Select',
}

const vacation = [
    "Within the Philippines", "Abroad"
]

const sick = [
    "In Hospital", "Out Patient"
]

const study = [
    "Completion of Master's Degree", "BAR/Board Examination Review"
]


const NewLeaveApplication = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const pos = offices.map(a => a.id).indexOf(currentUser.data.oid);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { type, details, specify, from, to, commutation } = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { id, value } = event.target;

        if (id === "type") {
            setFormFields({...formFields, [id]: value, details: "Select", specify: ""});
            return;
        }
        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (type === "Select") {
            alert("Select type");
            return;
        } else if (type === "Vacation Leave" && details === "Select" || type === "Special Privilege Leave" && details === "Select" ||
        type === "Sick Leave" && details === "Select" || type === "Study Leave" && details === "Select" || type === "Others" && details === "Select") {
            alert("Select details");
            return;
        } else if (commutation === "Select") {
            alert("Select commutation");
            return;
        }

        const resp = await createLeaveApplication({...formFields, "uid": currentUser.uid, "oid": currentUser.data.oid});
        if (resp.includes("error")) {
            alert(resp);
            return;
        }
        
        const log = {"action": "User applied for leave", "by" : currentUser.uid}
        await createLog(log);

        navigate("/leave-applications/" + resp);
    }
    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Leave Application</h2>
                        <div className="block pb-6">
                            <form onSubmit={handleSubmit}>
                                <div className='columns is-multiline'>
                                    <FormSelect options={["Vacation Leave", "Mandatory/Forced Leave", "Sick Leave", "Maternity Leave", "Paternity Leave",
                                    "Special Privilege Leave", "Solo Parent Leave", "Study Leave", "10-Day VAWC Leave", "Rehabilitation Privilege",
                                    "Special Leave Benefits for Women", "Special Emergency (Calamity) Leave", "Adoption Leave", "Others"]} type="text" required id="type" value={type} onChange={handleChange} label="Type" additionalClasses="column is-6"/>

                                    {type === "Vacation Leave" || type === "Special Privilege Leave" || type === "Sick Leave" || type === "Study Leave" ?
                                    <Fragment>
                                        <FormSelect options={type === "Vacation Leave" || type === "Special Privilege Leave" ? vacation : type === "Sick Leave" ? sick : study} type="text" required id="details" value={details} onChange={handleChange} label="Details" additionalClasses="column is-3"/>
                                        <FormInput type="text" required id="specify" value={specify} onChange={handleChange} label="Specify" additionalClasses="column is-3"/>
                                    </Fragment> :
                                    type === "Special Leave Benefits for Women" ?
                                    <FormInput type="text" required id="specify" value={specify} onChange={handleChange} label="Specify" additionalClasses="column is-6"/> :
                                    type === "Others" ? 
                                    <FormSelect options={["Monetization of Leave Credits", "Terminal Leave"]} type="text" required id="details" value={details} onChange={handleChange} label="Other Purpose" additionalClasses="column is-6"/>
                                    : ""}


                                    <FormInput type="date" required id="from" value={from} onChange={handleChange} label="Date (FROM)" additionalClasses="column is-6"/>
                                    <FormInput type="date" required id="to" value={to} onChange={handleChange} label="Date (TO)" additionalClasses="column is-6"/>
                                   
                                    <FormSelect options={["Not Requested", "Requested"]} type="text" required id="commutation" value={commutation} onChange={handleChange} label="Commutation" additionalClasses="column is-6"/>
                                </div>
                                <Button additionalClasses="block is-success" type="submit">Submit</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewLeaveApplication;