import Button from "./button";
import {useNavigate} from 'react-router-dom';

const OfficeItem = ({office, additionalClasses}) => {
    const { name, short } = office;
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate('/offices/' + office.id);
      }

    return (
        <div className={additionalClasses}>
            <div className="card">
                <div className="card-content">
                    <div className="content has-text-centered px-4 py-4" style={{height: 200 + "px"}}>
                        <h1 className='is-size-3'>{short}</h1>
                        {name}
                        <br/>
                        <br/>
                        <Button type="button" onClick={onClickHandler}>View</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfficeItem;