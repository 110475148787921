import { initializeApp } from 'firebase/app'
import { getAuth, signInWithRedirect, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, updatePassword } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, addDoc, collection, query, where, getDocs, orderBy, startAt, endAt, updateDoc, getCountFromServer, writeBatch, collectionGroup, arrayUnion, deleteDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import CryptoJS from 'crypto-js';
import { format } from "date-fns";

const firebaseConfig = {
  apiKey: "AIzaSyCWsukn8wSQumr89pj2FtSvG65iuJzMwIk",
  authDomain: "hris-e6d93.firebaseapp.com",
  projectId: "hris-e6d93",
  storageBucket: "hris-e6d93.appspot.com",
  messagingSenderId: "207524584246",
  appId: "1:207524584246:web:f95b7d32d6cb57a0a1b197"
};
  
  const app = initializeApp(firebaseConfig);

  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({
    prompt: "select_account"
  });

  export const auth = getAuth();
  export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
  export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);
  export const storage = getStorage();
  
  export const db = getFirestore();

  export const resetPassword = async (email) => {
    var response = "success";
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const createUserDocument = async (userAuth, additionalInformation = {}) => {
    if (!userAuth) return;
    
    const userDocRef = doc(db, 'Users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);
    var data = userSnapshot.data();

    if (!userSnapshot.exists()) {
        const created = new Date();
        const password = CryptoJS.AES.encrypt("hris", 'HRISSecretKey').toString();
        data =  {
          password,
          created,
          ...additionalInformation
       };

        try {
            await setDoc(userDocRef, data)
        } catch (error) {
            console.log("error", error.message);
        }
    }

    userAuth["data"] = data;

    return userDocRef;
  }

  export const getAdminDocument = async (uid) => {
    const userDocRef = doc(db, 'Users', uid);
    var response;

    try {
      const doc = await getDoc(userDocRef);
      const data = doc.data();
      data["id"] = uid;
      response = data;
    } catch (error) {
      response = null;
    }

    return response;
  }

  export const getOfficeDocument = async (id) => {
    const userDocRef = doc(db, 'Offices', id);
    var response;

    try {
      const doc = await getDoc(userDocRef);
      const data = doc.data();
      data["id"] = id;
      response = data;
    } catch (error) {
      response = null;
    }

    return response;
  }

  export const getUserDocument = async (id) => {
    const userDocRef = doc(db, 'Users', id);
    var response;

    try {
      const doc = await getDoc(userDocRef);
      const data = doc.data();
      data["id"] = id;
      response = data;
    } catch (error) {
      response = null;
    }

    return response;
  }

  export const getUpdate = async (id) => {
    const userDocRef = doc(db, 'NewsUpdates', id);
    var response;

    try {
      const doc = await getDoc(userDocRef);
      const data = doc.data();
      data["id"] = id;
      response = data;
    } catch (error) {
      response = null;
    }

    return response;
  }

  export const getServiceRecord = async (uid, id) => {
    const userDocRef = doc(db, 'Users/' + uid + '/ServiceRecords', id);
    var response;

    try {
      const doc = await getDoc(userDocRef);
      const data = doc.data();
      data["id"] = id;
      response = data;
    } catch (error) {
      response = null;
    }

    return response;
  }

  export const updateOfficeDocument = async (uid, additionalInformation = {}) => {
    const userDocRef = doc(db, 'Offices', uid);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }
  
  export const updateLeaveDocument = async (uid, additionalInformation = {}) => {
    const userDocRef = doc(db, 'LeaveApplications', uid);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateTravelOrderDocument = async (id, additionalInformation = {}) => {
    const userDocRef = doc(db, 'TravelOrders', id);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateAdminDocument = async (uid, additionalInformation = {}) => {
    const userDocRef = doc(db, 'Users', uid);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const approveUserDocument = async (uid) => {
    const userDocRef = doc(db, 'Users', uid);
    var response;

    try {
      await updateDoc(userDocRef, {approved: true});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const disapproveUserDocument = async (uid) => {
    const userDocRef = doc(db, 'Users', uid);
    var response;

    try {
      await updateDoc(userDocRef, {deactivated: true});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateUserDocument = async (uid, additionalInformation = {}) => {
    const userDocRef = doc(db, 'Users', uid);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateUpdate = async (id, additionalInformation = {}) => {
    const userDocRef = doc(db, 'NewsUpdates', id);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const createLeaveCredit = async (uid, id, additionalInformation = {}) => {
    var response;

    try {
      const created = new Date();
      if (id) {
        await setDoc(doc(db, 'Users/' + uid + '/LeaveCredits', id), {created, ...additionalInformation});
      } else {
        await addDoc(collection(db, 'Users/' + uid + '/LeaveCredits'), {created, ...additionalInformation});
      }
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateServiceRecord = async (uid, id, additionalInformation = {}) => {
    const userDocRef = doc(db, 'Users/' + uid + '/ServiceRecords', id);
    var response;

    try {
      await updateDoc(userDocRef, {...additionalInformation});
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const deleteServiceRecord = async (uid, id) => {
    const userDocRef = doc(db, 'Users/' + uid + '/ServiceRecords', id);
    var response;

    try {
      await deleteDoc(userDocRef);
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const deleteUpdate = async (id) => {
    const userDocRef = doc(db, 'NewsUpdates', id);
    var response;

    try {
      await deleteDoc(userDocRef);
      response = "success";
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const getOffices = async () => {
    const docRef = collection(db, 'Offices');
    const offices = [];
    const q = query(docRef, orderBy("name", "asc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        offices.push(data);
    });
    } catch (error) {
      console.log("error", error.message);
    }

    return offices;
  }

  export const getNotifications = async (type, oid, uid) => {
    const offices = [];
    var q;
    const date = new Date();
    switch(type) {
      case "user":
        q = query(collection(db, 'Users/' + uid + '/Notifications'), where("on", "<", date), orderBy("on", "desc"));
        break;

      case "superadmin":
        q = query(collectionGroup(db, 'Notifications'), where("on", "<", date), orderBy("on", "desc"));
        break;

      default:
        q = query(collectionGroup(db, 'Notifications'), where("oid", "==", oid), where("on", "<", date), orderBy("on", "desc"));
        break;
    }

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        offices.push(data);
      });
    } catch (error) {
      console.log("error", error.message);
    }

    return offices;
  }

  export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);
  }

  export const signInUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
  }

  export const signOutUser = async () => await signOut(auth);

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

  export const getUserDocuments = async (approved) => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, where("type", "==", "user"), where("approved", "==", approved), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getTravelOrders = async () => {
    const documentRef = collection(db, "TravelOrders");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getUpdates = async () => {
    const documentRef = collection(db, "NewsUpdates");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }
  

  export const getServiceRecords = async (id) => {
    const documentRef = collection(db, "Users/" + id + "/ServiceRecords");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getApprovedTravelOrders = async () => {
    const documentRef = collection(db, "TravelOrders");
    const userDocs = [];
    var q = query(documentRef, where("status", "==", "Approved"), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getTravelOrdersByOffice = async (office) => {
    const documentRef = collection(db, "TravelOrders");
    const userDocs = [];
    var q = query(documentRef, where("oid", "==", office), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getUserTravelOrders = async (id) => {
    const documentRef = collection(db, "TravelOrders");
    const userDocs = [];
    var q = query(documentRef, where("ids", "array-contains", id), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getLeaveApplications = async () => {
    const documentRef = collection(db, "LeaveApplications");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getLeaveCredits = async (id) => {
    const documentRef = collection(db, "Users/" + id + "/LeaveCredits");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getApprovedLeaveApplications = async () => {
    const documentRef = collection(db, "LeaveApplications");
    const userDocs = [];
    var q = query(documentRef, where("status", "==", "For GO Approval"), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getLeaveApplicationsByOffice = async (office) => {
    const documentRef = collection(db, "LeaveApplications");
    const userDocs = [];
    var q = query(documentRef, where("oid", "==", office), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getUserLeaveApplications = async (id) => {
    const documentRef = collection(db, "LeaveApplications");
    const userDocs = [];
    var q = query(documentRef, where("uid", "==", id), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getContractDocuments = async () => {
    const documentRef = collection(db, "Contracts");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getAdminDocuments = async () => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, where("type", "==", "admin"), orderBy("name", "asc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getTravelOrder = async (id) => {
    const documentRef = doc(db, "TravelOrders", id);
    var docRef = null;

    try {
      const doc = await getDoc(documentRef);
      docRef = doc.data();
      docRef["id"] = id;
    } catch (error) {
      console.log("error", error.message);
    }

    return docRef;
  }

  export const downloadFile = (fileName, url) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      };
      xhr.open('GET', url);
      xhr.send();
    } catch (error) {
      console.log(error);
    }
  }

  export const getLeaveApplication = async (id) => {
    const documentRef = doc(db, "LeaveApplications", id);
    var docRef = null;

    try {
      const doc = await getDoc(documentRef);
      docRef = doc.data();
      docRef["id"] = id;
    } catch (error) {
      console.log("error", error.message);
    }

    return docRef;
  }

  export const getContract = async (id) => {
    const documentRef = doc(db, "Contracts", id);
    var docRef = null;

    try {
      const doc = await getDoc(documentRef);
      docRef = doc.data();
      docRef["id"] = id;
    } catch (error) {
      console.log("error", error.message);
    }

    return docRef;
  }

  export const getUsersByOffice = async (office) => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, where("type", "==", "user"), where("oid", "==", office), where("approved", "==", true), orderBy("surname", "asc"), orderBy("firstName", "asc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getUsers = async () => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getUsersByEmployment = async (employment) => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, orderBy("surname", "desc"), orderBy("firstName", "desc"));
    if (employment !== "All") {
      q = query(documentRef, where("employment", "==", employment), orderBy("surname", "desc"), orderBy("firstName", "desc"));
    }

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const adminSearchUsers = async (search) => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, where("type", "==", "user"), where("approved", "==", true), where('search', 'array-contains-any', search));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const searchUsers = async (search, participants) => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, where("type", "==", "user"), where("approved", "==", true), where('search', 'array-contains-any', search));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        
        const pos = participants.map(a => a.id).indexOf(doc.id);
        if (pos === -1) {
          const data = doc.data();

          if (search.every(r => data.search.includes(r))) {
            const dat = {id: doc.id, surname: data.surname, firstName: data.firstName, middleName: data.middleName, oid: data.oid};
            userDocs.push(dat);
          }
        }
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const searchContractUsers = async (search, participants) => {
    const documentRef = collection(db, "Users");
    const userDocs = [];
    var q = query(documentRef, where("type", "==", "user"), where("approved", "==", true), where("employment", "in", ["Job Order", "COS"]), where('search', 'array-contains-any', search));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        
        const pos = participants.map(a => a.id).indexOf(doc.id);
        if (pos === -1) {
          const data = doc.data();

          if (search.every(r => data.search.includes(r))) {
            const dat = {id: doc.id, surname: data.surname, firstName: data.firstName, middleName: data.middleName, oid: data.oid};
            userDocs.push(dat);
          }
        }
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const getUserCount = async(office, employment) => {
    const documentRef = collection(db, "Users");

    var snapshot;
    if (employment === "All") {
      if (office) {
        const q = query(documentRef, where("type", "==", "user"), where("oid", "==", office), where("approved", "==", true));
        snapshot = await getCountFromServer(q);
      } else {
        const q = query(documentRef, where("type", "==", "user"), where("approved", "==", true));
        snapshot = await getCountFromServer(q);
      }
      return snapshot.data().count;
    }

    var q;
    if (office) {
      q = query(documentRef, where("oid", "==", office), where("employment", "==", employment), where("approved", "==", true))
    } else {
      q = query(documentRef, where("employment", "==", employment), where("approved", "==", true));
    }
    snapshot = await getCountFromServer(q);
    
    return snapshot.data().count;
  }

  export const createOffice = async (additionalInformation = {}) => {
    const ref = collection(db, "Offices");
    var response;

    try {
      const doc = await addDoc(ref, {
          ...additionalInformation
      });
      response = doc.id;
    } catch (error) {
      response = "error";
    }

    return response;
  }

  export const createUpdate = async (additionalInformation = {}) => {
    const ref = collection(db, "NewsUpdates");
    var response;

    try {
      const created = new Date();
      const doc = await addDoc(ref, {created,
          ...additionalInformation
      });
      response = doc.id;
    } catch (error) {
      response = "error";
    }

    return response;
  }

  export const createContract = async (additionalInformation = {}) => {
    const ref = collection(db, "Contracts");
    var response;

    try {
      const created = new Date();
      const doc = await addDoc(ref, {created,
          ...additionalInformation
      });
      response = doc.id;
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const updateUserContract = async(id, list) => {
    const batch = writeBatch(db);

    list.forEach((item) => {
      const {user, designation, to} = item;
      const ref = doc(db, "Users", user.id);
      batch.update(ref, {designation, "contract" : id, "until": new Date(to)});

      const notRef = doc(db, "Users/" + user.id + "/Notifications", id);
      const date = format(new Date(to), "MMMM dd, yyyy");
      const on = new Date(to);
      on.setMonth(on.getMonth() - 1);

      batch.set(notRef, {message: date, "on": on, "oid": user.oid, "type": "contract", "seen": [], "uid": user.id});
    });

    var res = "success";
    try {
      await batch.commit();
    } catch (error) {
      res = error.message;
    }

    return res;
  }

  export const updateUserNotifications = async(id, list, additionalInformation = {}) => {
    const batch = writeBatch(db);

    list.forEach((uid) => {
      const ref = doc(db, "Users/" + uid + "/Notifications", id);
      batch.update(ref, {...additionalInformation});
    });

    var res = "success";
    try {
      await batch.commit();
    } catch (error) {
      res = error.message;
    }

    return res;
  }

  export const updateNotificationSeen = (uid, nid, id) => {
    const userDocRef = doc(db, "Users/" + uid + "/Notifications", nid);
    updateDoc(userDocRef, {"seen": arrayUnion(id)});
  }

  export const createTravelNotification = async(id, list, to) => {
    const batch = writeBatch(db);

    list.forEach((user) => {
      const notRef = doc(db, "Users/" + user.id + "/Notifications", id);
      const on = new Date(to);
      const date = format(on, "MMMM dd, yyyy");

      batch.set(notRef, {message: date, "on": on, "oid": user.oid, "type": "travel", "seen": [], "uid": user.id});
    });

    var res = "success";
    try {
      await batch.commit();
    } catch (error) {
      res = error.message;
    }

    return res;
  }

  export const createUserNotification = async (uid, additionalInformation = {}) => {
    const ref = collection(db, "Users/" + uid + "/Notifications");
    var response;

    try {
      const doc = await addDoc(ref, {
          ...additionalInformation
      });
      response = "success";
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const createTravelOrder = async (additionalInformation = {}) => {
    const ref = collection(db, "TravelOrders");
    var response;

    try {
      const created = new Date();
      const doc = await addDoc(ref, {
        created,
          ...additionalInformation
      });
      response = doc.id;
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const createLeaveApplication = async (additionalInformation = {}) => {
    const ref = collection(db, "LeaveApplications");
    var response;

    try {
      const created = new Date();
      const doc = await addDoc(ref, {
        created,
          ...additionalInformation
      });
      response = doc.id;
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const createServiceRecord = async (uid, additionalInformation = {}) => {
    const ref = collection(db, "Users/" + uid + "/ServiceRecords");
    var response = "success";

    try {
      await addDoc(ref, {
          ...additionalInformation
      });
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const uploadContract = async(file) => {
    const date = new Date();
    const storageRef = ref(storage, 'contracts/' + date.getTime() + "_" + file.name);

    var res;
    try {
      const upload = await uploadBytes(storageRef, file);
      res = getDownloadURL(upload.ref);
    } catch (error) {
      res = "error: " + error.message;
    }

    return res;
  }

  export const uploadUpdate = async(file) => {
    const date = new Date();
    const storageRef = ref(storage, 'updates/' + date.getTime() + "_" + file.name);

    var res;
    try {
      const upload = await uploadBytes(storageRef, file);
      res = getDownloadURL(upload.ref);
    } catch (error) {
      res = "error: " + error.message;
    }

    return res;
  }

  export const uploadTravelReport = async(file, id) => {
    const date = new Date();
    const storageRef = ref(storage, 'travels/' + id + '/' + date.getTime() + "_" + file.name);

    var res;
    try {
      const upload = await uploadBytes(storageRef, file);
      res = getDownloadURL(upload.ref);
    } catch (error) {
      res = "error: " + error.message;
    }

    return res;
  }

  export const uploadFile = async(id, file) => {
    const date = new Date();
    const storageRef = ref(storage, 'users/' + id + '/' + date.getTime() + "_" + file.name);

    var res;
    try {
      const upload = await uploadBytes(storageRef, file);
      res = getDownloadURL(upload.ref);
    } catch (error) {
      res = "error: " + error.message;
    }

    return res;
  }

  export const createLog = async (log) => {
    const ref = collection(db, 'Logs');

    try {
      const created = new Date();
      await addDoc(ref, {
        created,
          ...log
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  export const getLogs = async () => {
    const documentRef = collection(db, "Logs");
    const userDocs = [];
    var q = query(documentRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          userDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return userDocs;
  }

  export const updateUserPassword = async (password) => {
    var response = "success";
    try {
      const ciphertext = CryptoJS.AES.encrypt(password, 'HRISSecretKey').toString();

      // const bytes  = CryptoJS.AES.decrypt(ciphertext, 'HRISSecretKey');
      // const originalText = bytes.toString(CryptoJS.enc.Utf8);

      await updatePassword(auth.currentUser, password);


      const userDocRef = doc(db, 'Users', auth.currentUser.uid);
      await updateDoc(userDocRef, {"password": ciphertext});
    } catch (error) {
      response = error.message;
    }
  
    return response;
  }