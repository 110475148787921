import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../../context/user-context";
import { downloadFile, getLeaveCredits, getUserDocument } from '../../utils/firebase';
import { useLocation, useNavigate } from 'react-router-dom'
import { Fragment } from 'react';
import { OfficesContext } from '../../context/offices-context';
import { format } from "date-fns";
import Button from '../../components/button';


const PersonalDataSheet = () => {
    const { currentUser } = useContext(UserContext);
    const [user, setUser] = useState(currentUser.data);
    const location = useLocation();
    const { offices } = useContext(OfficesContext);
    const [leaveCredits, setLeaveCredits = () => []] = useState([]);
    const navigate = useNavigate();
    const id = location.pathname.replace("/pds/", "");

    useEffect(() => {
        const getUsers = async () => {
            const doc = await getUserDocument(id);
            setUser(doc);
        }

        if (currentUser.data.type !== "user") {
            getUsers();
        }
    }, []);

    useEffect(() => {
        const get = async () => {
            var docs = await getLeaveCredits(id);
            setLeaveCredits(docs);
        }
        if (currentUser.data.type === "superadmin") {
            get();
        }
    }, []);

    const pos = offices.map(a => a.id).indexOf(user.oid);
    const { surname, firstName, middleName, nameExtension, emailAddress, personal, spouse, mother, father, children, education, eligibility, 
        work, voluntary, learning, skills, recognition, membership, files, employment } = user;

        var total = 0;
    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                        <div className="block pb-6 mt-6">
                        <div className='column is-12 has-text-centered'>
                            <figure className="image is-128x128 is-inline-block">
                            <img className="is-rounded" src={files ? files.photoFileUrl ? files.photoFileUrl : "https://bulma.io/images/placeholders/128x128.png" : "https://bulma.io/images/placeholders/128x128.png"}/>
                            </figure>
                        
                        <div className='column is-12 has-text-centered'>
                            <h2 className='is-size-2 has-text-weight-bold'>{middleName ? firstName + " " + middleName.substring(0, 1) + ". " + surname: firstName + " " + surname}</h2>
                            {pos > -1 ? <p className='is-size-5'>{offices[pos].name}</p> : ""}
                            <span className='tag is-success is-size-6 mt-2'>{employment}</span>
                        </div>
                        </div>

                        {currentUser.data.type === "superadmin" ? 
                        <Fragment>
                            <div className="notification is-success py-5 px-5 is-clickable" onClick={() => {navigate("/service-record/" + user.id)}}>
                            <strong>Service Record <i className="fas fa-arrow-right is-pulled-right mt-1"></i></strong> 
                            </div> 

                        <div className='card px-6 py-5 my-6'>
                            <h2 className='is-size-4 has-text-weight-bold'>Leave Credits History <Button additionalClasses="block is-success is-pulled-right" type="submit" onClick={() => {navigate("/leave-credits/" + id)}}>Add Credits</Button></h2>
                            <hr className='my-5'/>
                            <div className='block'>
                                {leaveCredits.map((leave) => {
                                total += leave.value;
                                    return (
                                        <div key={leave.id} className='py-4'>
                                        <span>{format(leave.created.toDate(), "MMMM dd, yyyy")}</span>
                                            <span className='has-text-weight-bold is-pulled-right'>{leave.value}</span>
                                        </div>
                                    )
                                })}

                                <div className='py-4'>
                                    <span>Remaining Credits:</span>
                                        <span className='has-text-weight-bold is-pulled-right'>{total}</span>
                                    </div>
                            </div>
                        </div>
                        </Fragment>: ""}

                        <div className='columns is-multiline'>
                            <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Personal Information</p>

                            <div className='column is-6'>
                                <label>Surname</label>
                                <p className='is-size-5 has-text-weight-medium'>{surname}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>First Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{firstName}</p>
                            </div>

                            <div className='column is-6'>
                                <label>Middle Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{middleName}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Name Extension</label>
                                <p className='is-size-5 has-text-weight-medium'>{nameExtension}</p>
                            </div>

                            {personal ? <Fragment>
                                <div className='column is-6'>
                                <label>Date of Birth</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.birthDate : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Place of Birth</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.birthPlace : "-"}</p>
                            </div>

                            <div className='column is-6'>
                                <label>Sex/Gender</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.sex : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Civil Status</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.civilStatus : "-"}</p>
                            </div>

                            <div className='column is-6'>
                                <label>Height</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.height : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Weight</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.weight : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Blood Type</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.bloodType : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>GSIS ID No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.gsisNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Pag-Ibig ID No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.pagibigNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Philhealth ID No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.philhealthNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>SSS No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.sssNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>TIN No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.tinNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Agency Employee No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.agencyNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Citizenship</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.citizenship : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>House/Block/Lot No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.houseNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Street</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.street : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Subdivion/Village</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.village : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Barangay</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.barangay : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Municipality</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.municipality : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Province</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.province : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Zip Code</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.zipCode : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Email Address</label>
                                <p className='is-size-5 has-text-weight-medium'>{emailAddress}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Telephone Number</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.telephoneNumber : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Mobile Number</label>
                                <p className='is-size-5 has-text-weight-medium'>{personal ? personal.mobileNumber : "-"}</p>
                            </div>
                            </Fragment>
                            : ""}

                            {spouse || father || mother ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Family Background</p> : ""}
                            
                            {spouse ? <Fragment>
                                <div className='column is-6'>
                                <label>Spouse Surname</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.surname ? spouse.surname : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Spouse First Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.firstName ? spouse.firstName : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Spouse Middle Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.middleName ? spouse.middleName : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Spouse Name Extension</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.nameExtension ? spouse.nameExtension : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Employer/Business Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.businessName ? spouse.businessName : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Business Address</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.businessAddress ? spouse.businessAddress : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Occupation</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.occupation ? spouse.occupation : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Telephone No.</label>
                                <p className='is-size-5 has-text-weight-medium'>{spouse && spouse.telephoneNumber ? spouse.telephoneNumber : "-"}</p>
                            </div>
                            </Fragment>
                            : ""}
                            
                            {father ? <Fragment>
                                <div className='column is-6'>
                                <label>Father Surname</label>
                                <p className='is-size-5 has-text-weight-medium'>{father && father.surname ? father.surname : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Father First Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{father && father.firstName ? father.firstName : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Father Middle Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{father && father.middleName ? father.middleName : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Father Name Extension</label>
                                <p className='is-size-5 has-text-weight-medium'>{father && father.nameExtension ? father.nameExtension : "-"}</p>
                            </div>
                            </Fragment>
                            : ""}

                            {mother ? <Fragment>
                                <div className='column is-6'>
                                <label>Mother Maiden Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{mother && mother.surname ? mother.surname : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Mother First Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{mother && mother.firstName ? mother.firstName : "-"}</p>
                            </div>
                            
                            <div className='column is-6'>
                                <label>Mother Middle Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{mother && mother.middleName ? mother.middleName : "-"}</p>
                            </div>

                            <div className='column is-6'/>
                            </Fragment>
                            : ""}

                            {children && children.map((child, index) => {
                                return (
                                    <Fragment key={"child_" + index}>
                                        <div className='column is-6'>
                                            <label>Child Name</label>
                                            <p className='is-size-5 has-text-weight-medium'>{child.fullName}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Date of Birth</label>
                                            <p className='is-size-5 has-text-weight-medium'>{child.birthDate}</p>
                                        </div>
                                    </Fragment>
                                )
                            })}

                            {education ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Educational Background</p> : ""}                            

                            {education && education.map((edu, index) => {
                                return (
                                    <Fragment key={"education_" + index}>
                                        <div className='column is-6'>
                                            <label>Level</label>
                                            <p className='is-size-5 has-text-weight-medium'>{edu.level}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>School Name</label>
                                            <p className='is-size-5 has-text-weight-medium'>{edu.schoolName}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Basic Education/Degree/Course</label>
                                            <p className='is-size-5 has-text-weight-medium'>{edu.course}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Highest Level/Units Earned</label>
                                            <p className='is-size-5 has-text-weight-medium'>{edu.unitsEarned ? edu.unitsEarned : "-"}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>From</label>
                                            <p className='is-size-5 has-text-weight-medium'>{edu.from}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>To</label>
                                            <p className='is-size-5 has-text-weight-medium'>{edu.to}</p>
                                        </div>
                                    </Fragment>
                                )
                            })}
                            
                            {eligibility ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Civil Service Eligibility</p> : ""}

                            {eligibility && eligibility.map((elig, index) => {
                                return (
                                    <Fragment key={"eligibility_" + index}>
                                        <div className='column is-6'>
                                            <label style={{textOverflow: "ellipsis", display: "block", overflow: "hidden", whiteSpace: "nowrap"}}>Career Service/RA 1080 (Board/BAR) Under Special Laws/CES/CSEE/Barangay Eligibility/Driver's License</label>
                                            <p className='is-size-5 has-text-weight-medium'>{elig.type}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Rating</label>
                                            <p className='is-size-5 has-text-weight-medium'>{elig.rating}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Date of Examination/Conferment</label>
                                            <p className='is-size-5 has-text-weight-medium'>{elig.date}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Place of Examination/Conferment</label>
                                            <p className='is-size-5 has-text-weight-medium'>{elig.place}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>License No.</label>
                                            <p className='is-size-5 has-text-weight-medium'>{elig.number}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Date of Validity</label>
                                            <p className='is-size-5 has-text-weight-medium'>{elig.validity}</p>
                                        </div>
                                    </Fragment>
                                )
                            })}

                            {work ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Work Experience</p> : ""}

                            {work && work.map((wor, index) => {
                                return (
                                    <Fragment key={"work_" + index}>
                                        <div className='column is-6'>
                                            <label>From</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.from}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>To</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.to}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Position Title</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.position}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Department/Agency/Office/Company</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.department}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Monthly Salary</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.salary}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Salary/Job/Pay Grade & Step</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.grade}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Status of Appointment</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.status}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Goverment Service</label>
                                            <p className='is-size-5 has-text-weight-medium'>{wor.goverment}</p>
                                        </div>
                                    </Fragment>
                                )
                            })}

                            {voluntary ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Voluntary Work</p> : ""}

                            {voluntary && voluntary.map((volu, index) => {
                                return (
                                    <Fragment key={"voluntary_" + index}>
                                        <div className='column is-6'>
                                            <label>Name & Address of Organization</label>
                                            <p className='is-size-5 has-text-weight-medium'>{volu.organization}</p>
                                        </div>

                                        <div className='column is-6'>
                                            <label>From</label>
                                            <p className='is-size-5 has-text-weight-medium'>{volu.from}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>To</label>
                                            <p className='is-size-5 has-text-weight-medium'>{volu.to}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>No. of Hours</label>
                                            <p className='is-size-5 has-text-weight-medium'>{volu.hours}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Position/Nature of Work</label>
                                            <p className='is-size-5 has-text-weight-medium'>{volu.position}</p>
                                        </div>
                                    </Fragment>
                                )
                            })}

                            {learning ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Learning & Development</p> : ""}

                            {learning && learning.map((learn, index) => {
                                return (
                                    <Fragment key={"learning_" + index}>
                                        <div className='column is-6'>
                                            <label>Title of Learning & Development Interventions/Training Programs</label>
                                            <p className='is-size-5 has-text-weight-medium'>{learn.title}</p>
                                        </div>

                                        <div className='column is-6'>
                                            <label>From</label>
                                            <p className='is-size-5 has-text-weight-medium'>{learn.from}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>To</label>
                                            <p className='is-size-5 has-text-weight-medium'>{learn.to}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>No. of Hours</label>
                                            <p className='is-size-5 has-text-weight-medium'>{learn.hours}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Type of LD</label>
                                            <p className='is-size-5 has-text-weight-medium'>{learn.type}</p>
                                        </div>
                                        
                                        <div className='column is-6'>
                                            <label>Conducted/Sponsored by</label>
                                            <p className='is-size-5 has-text-weight-medium'>{learn.sponsor}</p>
                                        </div>
                                    </Fragment>
                                )
                            })}

                                {skills || recognition || membership ? <Fragment>
                                    <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Other Information</p>

                                <div className='column is-6'>
                                    <label>Skills/Hobbies</label>
                                    {skills && skills.map((skill, index) => {
                                        return (
                                            <p className='is-size-5 has-text-weight-medium' key={"skill_" + index}>{skill}</p>
                                        )
                                    })}
                                </div>

                                <div className='column is-6'>
                                    <label>Recognition</label>
                                    {recognition && recognition.map((recog, index) => {
                                        return (
                                            <p className='is-size-5 has-text-weight-medium' key={"recognition_" + index}>{recog}</p>
                                        )
                                    })}
                                </div>

                                <div className='column is-6'>
                                    <label>Membership</label>
                                    {membership && membership.map((member, index) => {
                                        return (
                                            <p className='is-size-5 has-text-weight-medium' key={"membership_" + index}>{member}</p>
                                        )
                                    })}
                                </div>
                                </Fragment>
                                : ""}

                                {files ? <p className='is-size-3 has-text-weight-bold mt-6 mb-4 column is-12 is-paddingless'>Uploaded Files</p> : ""}

                                {files && files.drugTestFileName ? 
                                <div className='column is-6'>
                                    <label>Drug Test</label>
                                    <div className="file has-name is-fullwidth" onClick={() => downloadFile(files.drugTestFileName, files.drugTestFileUrl)}>
                                        <label className="file-label">
                                            <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-download"></i>
                                            </span>
                                            <span className="file-label">
                                                Download
                                            </span>
                                            </span>
                                            <span className="file-name">
                                                {files.drugTestFileName}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                : ""}

                                {files && files.cedulaFileName ? 
                                <div className='column is-6'>
                                    <label>Cedula</label>
                                    <div className="file has-name is-fullwidth" onClick={() => downloadFile(files.cedulaFileName, files.cedulaFileUrl)}>
                                        <label className="file-label">
                                            <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-download"></i>
                                            </span>
                                            <span className="file-label">
                                                Download
                                            </span>
                                            </span>
                                            <span className="file-name">
                                                {files.cedulaFileName}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                : ""}

                                {files && files.nbiCertificateFileName ? 
                                <div className='column is-6'>
                                    <label>NBI Certificate</label>
                                    <div className="file has-name is-fullwidth" onClick={() => downloadFile(files.nbiCertificateFileName, files.nbiCertificateFileUrl)}>
                                        <label className="file-label">
                                            <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-download"></i>
                                            </span>
                                            <span className="file-label">
                                                Download
                                            </span>
                                            </span>
                                            <span className="file-name">
                                                {files.nbiCertificateFileName}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                : ""}

                                {files && files.medicalCertificateFileName ? 
                                <div className='column is-6'>
                                    <label>Medical Certificate</label>
                                    <div className="file has-name is-fullwidth" onClick={() => downloadFile(files.medicalCertificateFileName, files.medicalCertificateFileUrl)}>
                                        <label className="file-label">
                                            <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-download"></i>
                                            </span>
                                            <span className="file-label">
                                                Download
                                            </span>
                                            </span>
                                            <span className="file-name">
                                                {files.medicalCertificateFileName}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default PersonalDataSheet;