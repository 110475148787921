import Button from "./button";
import {useNavigate} from 'react-router-dom';
import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addLocale(en)

const ContractItem = ({contract}) => {
    const { created, list, id } = contract;
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate('/contracts/' + id);
    }

    const tot = list.length - 1;
    var text = tot + " other";
    if (tot > 1) {
        text = tot + " others";
    }

    return (
        <article className="media">
            <div className="media-content">
                <div className="content">
                <p>
                    <strong>{list.length === 1 ? list[0].user.surname + "'s contract" : list[0].user.surname + " and " + text + " contract"}</strong>
                    <br/>
                    <ReactTimeAgo date={created.toDate()} locale="en-US"/>
                </p>
                </div>
            </div>
            <div className="media-right">
                <Button type="button" onClick={onClickHandler}>View</Button>
            </div>
        </article>
    )
}

export default ContractItem;