import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'javascript-time-ago'
import {useNavigate} from 'react-router-dom';
import en from 'javascript-time-ago/locale/en.json'
import Button from "./button";
import { getUserDocument, updateNotificationSeen } from "../utils/firebase";
import { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../context/notifications-context";
TimeAgo.addLocale(en)

const defaultUser = {
    surname: "",
    firstName: "",
    middleName: ""
}

const NotificationItem = ({notification, office, currentUser}) => {
    const { updateNotificationInContext } = useContext(NotificationsContext);
    const navigate = useNavigate();
    const [user, setUser] = useState(defaultUser);
    const { message, oid, uid, on, seen, type, submitted } = notification;
    const {surname, firstName, middleName} = user;
    const id = currentUser.uid;

    useEffect(() => {
        const getUsers = async () => {
            const response = await getUserDocument(uid);
            setUser(response);
        }
        if (id !== uid) {
            getUsers();
        }
    }, []);

    const date = new Date();
    var mes = "";
    if (id !== uid) {
        switch(type) {
            case "contract":
                const end = new Date(message);
                if (end < date) {
                    mes = firstName + " " + surname + "'s contract has ended on " + message;
                } else {
                    mes = firstName + " " + surname + "'s contract will end on " + message;
                }
                break;
            case "travel":
                if (submitted) {
                    mes = firstName + " " + surname + " has submitted a report on a travel order dated last " + message;
                } else {
                    mes = firstName + " " + surname + " has to submit a report regarding a travel order dated last " + message;
                }
                break;
            case "resignation":
                mes = firstName + " " + surname + " has submitted a resignation on " + message;
                break;
        }
    } else {
        switch(type) {
            case "contract":
                const end = new Date(message);
                if (end < date) {
                    mes = "Your contract has ended on " + message;
                } else {
                    mes = "Your contract will end on " + message;
                }
                break;
            case "travel":
                if (submitted) {
                    mes = "You have submitted a report on a travel order dated last " + message;
                } else {
                    mes = "You need to submit a report regarding a travel order dated last " + message;
                }
                break;
            case "resignation":
                mes = "You have submitted your resignation on " + message;
                break;
        }
    }

    if (!seen.includes(id)) {
        seen.push(id);
        updateNotificationSeen(uid, notification.id, id);
        updateNotificationInContext(notification);
    }

    var clickable = false;
    if (id !== uid) {
        if (type === "contract") {
            if (currentUser.data.type === "superadmin") {
                clickable = true;
            }
        } else {
            clickable = true;
        }
    } else {
        if (type === "contract") {
        } else {
            clickable = true;
        }
    }

    const onClickHandler = () => {
        if (id !== uid) {
            if (type === "contract") {
                if (currentUser.data.type === "superadmin") {
                    navigate("/contracts/" + notification.id);
                }
            } else {
                navigate("/travel-orders/" + notification.id);
            }
        } else {
            if (type === "contract") {
            } else {
                navigate("/travel-orders/" + notification.id);
            }
        }
    }

    return (
        <article onClick={onClickHandler} className={`media ${clickable ? "is-clickable" : ""}`} style={{backgroundColor: "white", padding: 2 + "rem", border: 0 + "px solid #000", borderRadius: 10 + "px"}}>
            <div className="media-content">
                <div className="content">
                <p>
                    <strong>{mes}</strong>
                    {uid !== id && office ? <small><br/>{office.short}<br/></small> : ""}
                    <br/>
                    <small> <ReactTimeAgo date={on.toDate()} locale="en-US"/></small>
                </p>
                </div>
            </div>
        </article>
    )
}

export default NotificationItem;