import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { getAdminDocuments, getLogs, getUsers } from '../utils/firebase';
import {useNavigate} from 'react-router-dom';
import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addLocale(en)

const ActivityLogs = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [logs, setLogs = () => []] = useState([]);
    const [page, setPage] = useState(0);
    const [users, setUsers] = useState([]);
    const maxDocs = 20;

    useEffect(() => {
        const get = async () => {
            const docs = await getUsers();
            setUsers(docs);
        }
        get();
    }, []);

    useEffect(() => {
        const get = async () => {
            const docs = await getLogs();
            setLogs(docs);
        }
        get();
    }, []);

    const pages = [];
    for (let i = 0; i < logs.length / maxDocs; i++) {
        pages.push(i);
    }
    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Activity Logs</h2>
                        <div className='block pb-6'>
                        <table className="table is-fullwidth is-borderless" style={{backgroundColor: "transparent"}}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Activity</th>
                                    <th>Date</th>
                                    <th>User</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {logs.map((log, index) => {
                                if (index >= (page * maxDocs) && index < maxDocs + (page * maxDocs)) {
                                    const {action, created, by} = log;
                                    const pos = users.map(a => a.id).indexOf(by);

                                    return (
                                        <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{log.action}</td>
                                        <td><ReactTimeAgo date={created.toDate()} locale="en-US"/></td>
                                        <td>{pos > -1 ? users[pos].type === "superadmin" ? "Super Admin" : users[pos].type === "admin" ? users[pos].name : users[pos].firstName + " " + users[pos].lastName : "Undefined"}</td>
                                    </tr>
                                    )
                                }
                            })}
                            </tbody>
                        </table>
                        </div>
                        <nav className="pagination is-right" role="navigation" aria-label="pagination">
                            <ul className="pagination-list mb-6">
                                {pages.map((p) => {
                                    if (pages.length > 1) {
                                        return <li key={p} ><a className={`${page === p ? "is-current" : ""} pagination-link`} onClick={() => {
                                            if (p !== page) {setPage(p); window.scrollTo({ top: 0, behavior: 'smooth' });}
                                        }}>{p + 1}</a></li>
                                    }}
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
    )
}

export default ActivityLogs;