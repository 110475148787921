import { useContext, useState, useEffect, Fragment } from 'react';
import { UserContext } from "../context/user-context";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../components/button';
import FormSelect from '../components/form-select';
import FormInput from '../components/form-input';
import { createServiceRecord } from '../utils/firebase';

const defaultFormFields = {
    from: '',
    to: '',
    designation: '',
    status: '',
    salary: '',
    station: '',
    branch: '',
    leave: '',
    separation: 'Select',
    date: '',
    cause: '',
}

const NewServiceRecord = () => {
    const { id } = useParams();
    const { currentUser } = useContext(UserContext);
    const location = useLocation();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const navigate = useNavigate();

    const { from, to, designation, status, salary, station, branch, leave, separation, date, cause } = formFields;

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (separation === "Select") {
            alert("Select separation");
            return;
        }

        const created = new Date(from);
        const response = await createServiceRecord(id, {...formFields, created, "uid": id});
        if (response === "success") {
            navigate("/service-record/" + id);
            return;
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Service Record</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                    <div className='columns is-multiline'>
                                        <FormInput type="date" required id="from" value={from} onChange={handleChange} label="Service (FROM)" additionalClasses="column is-6"/>
                                        <FormInput type="date" required id="to" value={to} onChange={handleChange} label="Service (TO)" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="designation" value={designation} onChange={handleChange} label="Designation" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="status" value={status} onChange={handleChange} label="Status" additionalClasses="column is-6"/>
                                        <FormInput type="number" step="0.01" required id="salary" value={salary} onChange={handleChange} label="Salary" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="station" value={station} onChange={handleChange} label="Station/Place of Assignment" additionalClasses="column is-6"/>
                                        <FormInput type="text" id="branch" value={branch} onChange={handleChange} label="Branch" additionalClasses="column is-6"/>
                                        <FormInput type="text" id="leave" value={leave} onChange={handleChange} label="Leave/Absent without Pay" additionalClasses="column is-6"/>
                                        <FormSelect type="text" required options={["Still in the service", "No longer in the service"]} id="separation" value={separation} onChange={handleChange} label="Separation" additionalClasses="column is-6"/>
                                        {separation === "No longer in the service" ? <Fragment>
                                        <FormInput type="date" required id="date" value={date} onChange={handleChange} label="Date" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="cause" value={cause} onChange={handleChange} label="Cause" additionalClasses="column is-6"/>
                                        </Fragment> : ""}
                                    </div>
                                    <Button additionalClasses="block is-success" type="submit">Submit</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewServiceRecord;