import { Fragment, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import seal from '../../assets/seal.png'
import background from '../../assets/background.jpg'
import { UserContext } from "../../context/user-context";
import { NotificationsContext } from "../../context/notifications-context";
import { createLog, createUserNotification, signOutUser, updateUserDocument } from "../../utils/firebase";
import {useNavigate} from 'react-router-dom';
import './navigation.scss';
import { useLocation } from 'react-router-dom'
import Button from "../../components/button";
import { useEffect } from "react";
import CryptoJS from 'crypto-js';
import { format } from "date-fns";

const Navigation = () => {
  const { notifications, updateNotificationInContext } = useContext(NotificationsContext);
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [unseen, setUnseen] = useState([]);

  useEffect(() => {
    const uns = notifications.filter((noti) => !noti.seen.includes(currentUser.uid));
    setUnseen(uns);
  }, [notifications]);


  const seen = () => {
    setUnseen([]);
  }

  const signOutHandler = async() => {
    signOutUser();
    navigate('/');
    setToggle(false);

    const log = {"action": "User signed out", "by" : currentUser.uid}
    await createLog(log);
  }

  const onMenuToggle = () => {
    setToggle(!toggle);
  }

  const onNavigate = (link) => {
    navigate(link);
    setToggle(false);
  }

  const resignHandler = async() => {
    const con = window.prompt("Type in your password");
    const bytes  = CryptoJS.AES.decrypt(currentUser.data.password, 'HRISSecretKey');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    if (con === originalText) {
      const res = await updateUserDocument(currentUser.uid, {"deactivated": true});

      if (res === "success") {
        const on = new Date();
        const date = format(on, "MMMM dd, yyyy");
        const notif = {message: date, "on": on, "oid": currentUser.data.oid, "type": "resignation", "seen": [], "uid": currentUser.uid};

        const re = await createUserNotification(currentUser.uid, notif);
        if (re === "success") {
          signOutHandler();
          return;
        }

        alert(re);
        return;
      }
      alert(res);
    } else {
      alert("Incorrect password");
    }
  }

  var deactivated = false;
  if (currentUser) {
    if (currentUser.data.deactivated) {
      deactivated = true;
    }

    if (currentUser.data.until) {
      const until = currentUser.data.until.toDate();
      const now = new Date();
      deactivated = now.getTime() > until.getTime();

      if (currentUser.data.deactivated && !deactivated) {
        currentUser.data["deactivated"] = false;
        updateUserDocument(currentUser.uid, {"deactivated": false});
      }

      if (!currentUser.data.deactivated && deactivated) {
        currentUser.data["deactivated"] = true;
        updateUserDocument(currentUser.uid, {"deactivated": true});
      }
    }
  }

  var bg = false;
  if (currentUser) {
    bg = location.pathname == "/";
    // if (currentUser.data.type === "user") {
    // } else {
    //   bg = location.pathname == "/news-updates" || location.pathname == "/";
    // }
  }

  return (
    <Fragment>
          <div className="columns is-marginless">
            <div className='column is-3 has-background-white is-hidden-mobile' style={{height :"100vh", overflow: "auto"}}>
                <div className='columns is-vcentered mt-4 ml-4'>
                    <div className='column is-narrow'>
                        <figure className="image is-64x64">
                            <img src={seal}/>
                        </figure>
                    </div>
                    <div className='column'>
                        <h2 className='is-size-4 has-text-weight-bold'>PGOCN-HRIS</h2>
                        <p className=''>Employee Information Management System</p>
                    </div>
                </div>

                <aside className="menu mx-6 my-6">
                  <p className="menu-label">
                    Menu
                  </p>
                  <ul className="menu-list">
                    
                  {currentUser ?
                  <Fragment>
                      <li><a className={location.pathname === "/" ? "is-active" : ""} onClick={() => navigate("/")}><i className="fas fa-home"></i> Home</a></li>
                      <li><a className={location.pathname === "/notifications" ? "is-active" : ""} onClick={() => {seen(); navigate("/notifications")}}><i className="fas fa-bell"></i> Notifications {unseen.length > 0 ? <span className="tag is-pulled-right is-danger">{unseen.length}</span> : ""}</a></li>
                      {currentUser.data.type === "user" ? 
                      <Fragment>
                        {currentUser.data.approved && !deactivated ?
                      <Fragment>
                      <li><a className={location.pathname === "/leave-applications" ? "is-active" : ""} onClick={() => navigate("/leave-applications")}><i className="fas fa-file-lines"></i> Leave Applications</a></li>
                        <li><a className={location.pathname === "/service-record/" + currentUser.uid ? "is-active" : ""} onClick={() => navigate("/service-record/" + currentUser.uid)}><i className="fas fa-file-invoice"></i> Service Record</a></li>
                        <li><a className={location.pathname === "/travel-orders" ? "is-active" : ""} onClick={() => navigate("/travel-orders")}><i className="fas fa-file"></i> Travel Orders</a></li>
                      </Fragment> : ""}
                      <li><a className={location.pathname === "/pds" ? "is-active" : ""} onClick={() => navigate("/pds")}><i className="fas fa-user"></i> Personnal Data Sheet</a>
                        <ul>
                          <li><a className={location.pathname === "/pds/personal-information" ? "is-active" : ""} onClick={() => navigate("/pds/personal-information")}>Personal Information</a></li>
                          <li><a className={location.pathname === "/pds/family-background" ? "is-active" : ""} onClick={() => navigate("/pds/family-background")}>Family Background</a></li>
                          <li><a className={location.pathname === "/pds/educational-background" ? "is-active" : ""} onClick={() => navigate("/pds/educational-background")}>Educational Background</a></li>
                          <li><a className={location.pathname === "/pds/civil-service-eligibility" ? "is-active" : ""} onClick={() => navigate("/pds/civil-service-eligibility")}>Civil Service Eligibility</a></li>
                          <li><a className={location.pathname === "/pds/work-experience" ? "is-active" : ""} onClick={() => navigate("/pds/work-experience")}>Work Experience</a></li>
                          <li><a className={location.pathname === "/pds/voluntary-work" ? "is-active" : ""} onClick={() => navigate("/pds/voluntary-work")}>Voluntary Work</a></li>
                          <li><a className={location.pathname === "/pds/trainings-attended" ? "is-active" : ""} onClick={() => navigate("/pds/learning-development")}>Learning & Development</a></li>
                          <li><a className={location.pathname === "/pds/other-information" ? "is-active" : ""} onClick={() => navigate("/pds/other-information")}>Other Information</a></li>
                          <li><a className={location.pathname === "/pds/file-upload" ? "is-active" : ""} onClick={() => navigate("/pds/file-upload")}>File Upload</a></li>
                        </ul>
                      </li>
                    </Fragment>
                    :
                    currentUser.data.type === "superadmin" ?
                    <Fragment>
                    <li><a className={location.pathname === "/news-updates" ? "is-active" : ""} onClick={() => onNavigate("/news-updates")}><i className="fas fa-newspaper"></i> News & Updates</a></li>
                    <li><a className={location.pathname === "/applicants" ? "is-active" : ""} onClick={() => navigate("/applicants")}><i className="fas fa-users"></i> Applicants</a></li>
                    <li><a className={location.pathname === "/accounts" ? "is-active" : ""} onClick={() => navigate("/accounts")}><i className="fas fa-user"></i> Accounts</a></li>
                    <li><a className={location.pathname === "/contracts" ? "is-active" : ""} onClick={() => navigate("/contracts")}><i className="fas fa-file-contract"></i> Contracts</a></li>
                    <li><a className={location.pathname === "/leave-applications" ? "is-active" : ""} onClick={() => onNavigate("/leave-applications")}><i className="fas fa-file-lines"></i> Leave Applications</a></li>
                    <li><a className={location.pathname === "/offices" ? "is-active" : ""} onClick={() => navigate("/offices")}><i className="fas fa-building"></i> Offices</a></li>
                    <li><a className={location.pathname === "/travel-orders" ? "is-active" : ""} onClick={() => navigate("/travel-orders")}><i className="fas fa-file"></i> Travel Orders</a></li>
                    <li><a className={location.pathname === "/activity-logs" ? "is-active" : ""} onClick={() => navigate("/activity-logs")}><i className="fas fa-clock-rotate-left"></i> Activity Logs</a></li>
                    <li><a className={location.pathname === "/ighrs-report" ? "is-active" : ""} onClick={() => onNavigate("/ighrs-report")}><i className="fas fa-file-arrow-down"></i> IGHRS Report</a></li>
                    </Fragment>
                    :
                    <Fragment>
                    <li><a className={location.pathname === "/news-updates" ? "is-active" : ""} onClick={() => onNavigate("/news-updates")}><i className="fas fa-newspaper"></i> News & Updates</a></li>
                    <li><a className={location.pathname === "/leave-applications" ? "is-active" : ""} onClick={() => onNavigate("/leave-applications")}><i className="fas fa-file-lines"></i> Leave Applications</a></li>
                    <li><a className={location.pathname === "/travel-orders" ? "is-active" : ""} onClick={() => navigate("/travel-orders")}><i className="fas fa-file"></i> Travel Orders</a></li>
                    </Fragment>
                    }
                    
                    <li><a className={location.pathname === "/change-password" ? "is-active" : ""} onClick={() => navigate("/change-password")}><i className="fas fa-key"></i> Change Password</a></li>
                    </Fragment>
                    :
                    <Fragment>
                      <li><a className={location.pathname === "/" ? "is-active" : ""} onClick={() => navigate("/")}><i className="fas fa-home"></i> Home</a></li>
                    </Fragment>
                    }
                  </ul>
                </aside>
                <div className="px-6 pb-6">
                  {currentUser && currentUser.data.type === "user" && !currentUser.data.deactivated ?
                    <Button type="button" additionalClasses="is-fullwidth is-danger mb-4" onClick={resignHandler}>Resign</Button>
                      : ""}
                    {currentUser ?
                    <Button type="button" additionalClasses="is-fullwidth" onClick={signOutHandler}>Sign out</Button>
                      : ""}
                </div>
            </div>
  <nav className="navbar is-hidden-tablet column is-12" role="navigation" aria-label="main navigation" style={{padding: "0px"}}>
  <div className="navbar-brand">
    <a className="navbar-item" onClick={() => navigate("/")}>
      <img src={seal}/>
      <h1 className="is-size-5 ml-5">
      PGOCN-HRIS<br/>
        <small>Employee Information Management System</small>
        </h1>
    </a>

    <a onClick={onMenuToggle} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" className={`navbar-menu ${toggle ? "is-active" : ""}`} style={{maxHeight: 600 + "px", overflow: "auto"}}>
    <div className="navbar-start">
    <aside className="menu mx-6 my-6">
                  <p className="menu-label">
                    Menu
                  </p>
                  <ul className="menu-list">
                    
                  {currentUser ?
                  <Fragment>
                      <li><a className={location.pathname === "/" ? "is-active" : ""} onClick={() => onNavigate("/")}><i className="fas fa-home"></i> Home</a></li>
                      <li><a className={location.pathname === "/notifications" ? "is-active" : ""} onClick={() => {seen(); navigate("/notifications")}}><i className="fas fa-bell"></i> Notifications {unseen.length > 0 ? <span className="tag is-pulled-right is-danger">{unseen.length}</span> : ""}</a></li>
                      {currentUser.data.type === "user" ? 
                      <Fragment>
                        {currentUser.data.approved && !deactivated ?
                        <Fragment>
                          <li><a className={location.pathname === "/leave-applications" ? "is-active" : ""} onClick={() => onNavigate("/leave-applications")}><i className="fas fa-file-lines"></i> Leave Applications</a></li>
                        <li><a className={location.pathname === "/service-record/" + currentUser.uid ? "is-active" : ""} onClick={() => navigate("/service-record/" + currentUser.uid)}><i className="fas fa-file-invoice"></i> Service Record</a></li>
                          <li><a className={location.pathname === "/travel-orders" ? "is-active" : ""} onClick={() => onNavigate("/travel-orders")}><i className="fas fa-file"></i> Travel Orders</a></li>
                        </Fragment> : ""}
                      <li><a className={location.pathname === "/pds" ? "is-active" : ""} onClick={() => onNavigate("/pds")}><i className="fas fa-user"></i> Personnal Data Sheet</a>
                        <ul>
                          <li><a className={location.pathname === "/pds/personal-information" ? "is-active" : ""} onClick={() => onNavigate("/pds/personal-information")}>Personal Information</a></li>
                          <li><a className={location.pathname === "/pds/family-background" ? "is-active" : ""} onClick={() => onNavigate("/pds/family-background")}>Family Background</a></li>
                          <li><a className={location.pathname === "/pds/educational-background" ? "is-active" : ""} onClick={() => onNavigate("/pds/educational-background")}>Educational Background</a></li>
                          <li><a className={location.pathname === "/pds/civil-service-eligibility" ? "is-active" : ""} onClick={() => onNavigate("/pds/civil-service-eligibility")}>Civil Service Eligibility</a></li>
                          <li><a className={location.pathname === "/pds/work-experience" ? "is-active" : ""} onClick={() => onNavigate("/pds/work-experience")}>Work Experience</a></li>
                          <li><a className={location.pathname === "/pds/voluntary-work" ? "is-active" : ""} onClick={() => onNavigate("/pds/voluntary-work")}>Voluntary Work</a></li>
                          <li><a className={location.pathname === "/pds/trainings-attended" ? "is-active" : ""} onClick={() => onNavigate("/pds/learning-development")}>Learning & Development</a></li>
                          <li><a className={location.pathname === "/pds/other-information" ? "is-active" : ""} onClick={() => onNavigate("/pds/other-information")}>Other Information</a></li>
                          <li><a className={location.pathname === "/pds/file-upload" ? "is-active" : ""} onClick={() => onNavigate("/pds/file-upload")}>File Upload</a></li>
                        </ul>
                      </li>
                    </Fragment>
                    :
                    currentUser.data.type === "superadmin" ?
                    <Fragment>
                    <li><a className={location.pathname === "/news-updates" ? "is-active" : ""} onClick={() => onNavigate("/news-updates")}><i className="fas fa-newspaper"></i> News & Updates</a></li>
                    <li><a className={location.pathname === "/applicants" ? "is-active" : ""} onClick={() => onNavigate("/applicants")}><i className="fas fa-users"></i> Applicants</a></li>
                    <li><a className={location.pathname === "/accounts" ? "is-active" : ""} onClick={() => onNavigate("/accounts")}><i className="fas fa-user"></i> Accounts</a></li>
                    <li><a className={location.pathname === "/contracts" ? "is-active" : ""} onClick={() => onNavigate("/contracts")}><i className="fas fa-file-contract"></i> Contracts</a></li>
                    <li><a className={location.pathname === "/leave-applications" ? "is-active" : ""} onClick={() => onNavigate("/leave-applications")}><i className="fas fa-file-lines"></i> Leave Applications</a></li>
                    <li><a className={location.pathname === "/offices" ? "is-active" : ""} onClick={() => onNavigate("/offices")}><i className="fas fa-building"></i> Offices</a></li>
                    <li><a className={location.pathname === "/travel-orders" ? "is-active" : ""} onClick={() => onNavigate("/travel-orders")}><i className="fas fa-file"></i> Travel Orders</a></li>
                    <li><a className={location.pathname === "/activity-logs" ? "is-active" : ""} onClick={() => onNavigate("/activity-logs")}><i className="fas fa-clock-rotate-left"></i> Activity Logs</a></li>
                    <li><a className={location.pathname === "/ighrs-report" ? "is-active" : ""} onClick={() => onNavigate("/ighrs-report")}><i className="fas fa-file-arrow-down"></i> IGHRS Report</a></li>
                    </Fragment>
                    :
                    <Fragment>
                    <li><a className={location.pathname === "/news-updates" ? "is-active" : ""} onClick={() => onNavigate("/news-updates")}><i className="fas fa-newspaper"></i> News & Updates</a></li>
                    <li><a className={location.pathname === "/leave-applications" ? "is-active" : ""} onClick={() => onNavigate("/leave-applications")}><i className="fas fa-file-lines"></i> Leave Applications</a></li>
                    <li><a className={location.pathname === "/travel-orders" ? "is-active" : ""} onClick={() => onNavigate("/travel-orders")}><i className="fas fa-file"></i> Travel Orders</a></li>
                    </Fragment>
                    }
                    
                    <li><a className={location.pathname === "/change-password" ? "is-active" : ""} onClick={() => onNavigate("/change-password")}><i className="fas fa-key"></i> Change Password</a></li>
                    </Fragment>
                    :
                    <Fragment>
                      <li><a className={location.pathname === "/" ? "is-active" : ""} onClick={() => onNavigate("/")}><i className="fas fa-home"></i> Home</a></li>
                    </Fragment>
                    }
                  </ul>
                </aside>
                
                <div className="px-6 pb-6">
                  {currentUser && currentUser.data.type === "user" && !currentUser.data.deactivated ?
                    <Button type="button" additionalClasses="is-fullwidth is-danger mb-4" onClick={resignHandler}>Resign</Button>
                      : ""}
                    {currentUser ?
                    <Button type="button" additionalClasses="is-fullwidth" onClick={signOutHandler}>Sign out</Button>
                      : ""}
                </div>
    </div>
  </div>
</nav>

{bg ? <div className="custom-background"/> : ""}
          <Outlet/>
        </div>
    </Fragment>
  )
}

export default Navigation;
