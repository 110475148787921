import { useContext, useState, useEffect, Fragment } from 'react';
import { UserContext } from "../context/user-context";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../components/button';
import FormSelect from '../components/form-select';
import FormInput from '../components/form-input';
import { createLeaveCredit, createServiceRecord } from '../utils/firebase';

const NewLeaveCredits = () => {
    const { id } = useParams();
    const { currentUser } = useContext(UserContext);
    const location = useLocation();
    const [value, setValue] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await createLeaveCredit(id, null, {"value": parseFloat(value)});
        if (response === "success") {
            navigate("/pds/" + id);
            return;
        }

        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Add Leave Credits</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                    <div className='columns is-multiline'>
                                        <FormInput type="number" step="0.01" required id="value" value={value} onChange={(e) => {setValue(e.target.value)}} label="Amount" additionalClasses="column is-12"/>
                                    </div>
                                    <Button additionalClasses="block is-success" type="submit">Submit</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewLeaveCredits;