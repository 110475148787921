import { useEffect, useState } from 'react';
import { getUserCount } from '../utils/firebase';

const AdminDashboardCard = ({employment, office, additionalClasses}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const getUsers = async () => {
            const response = await getUserCount(office, employment);
            setCount(response);
        }
        getUsers();
    }, []);

    return (
        <div className={additionalClasses}>
            <div className="card">
                <div className="card-content">
                    <div className="content has-text-centered py-4">
                        <h1 className='is-size-2'>{count}</h1>
                        <p><span className='is-size-6'>
                        <i className={`mr-3 fas ${employment === "Elected" ? "fa-user-tie" : employment === "Regular" ? "fa-user-group" : employment === "Casual" ? "fa-user-friends" :
                        employment === "Job Order" ? "fa-user-clock" : employment === "All" ? "fa-users" : "fa-user"}`}></i>
                        {employment}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboardCard;