import Button from "./button";
import {useNavigate} from 'react-router-dom';

const AdminItem = ({user}) => {
    const { name, emailAddress, id } = user;
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate('/accounts/' + id + '/update');
    }

    return (
        <article className="media">
            <figure className="media-left">
                <p className="image is-64x64">
                <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png"/>
                </p>
            </figure>
            <div className="media-content">
                <div className="content">
                <p>
                    <strong>{name}</strong>
                    <br/>
                    {emailAddress}
                </p>
                </div>
            </div>
            <div className="media-right">
                <Button type="button" onClick={onClickHandler}>Update</Button>
            </div>
        </article>
    )
}

export default AdminItem;