import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import EmployeeItem from '../components/employee-item';
import { getUsersByOffice } from '../utils/firebase';
import { OfficesContext } from '../context/offices-context';
import AdminDashboardCard from '../components/admin-dashboard-card';

const employment = [
    {
        "id": 1,
        "text": "All"
    },
    {
        "id": 2,
        "text": "Elected"
    },
    {
        "id": 3,
        "text": "Regular"
    },
    {
        "id": 4,
        "text": "Co-Term"
    },
    {
        "id": 5,
        "text": "Casual"
    },
    {
        "id": 6,
        "text": "COS"
    },
    {
        "id": 7,
        "text": "Job Order"
    }
]

const Employees = () => {
    const { currentUser } = useContext(UserContext);
    const [users, setUsers = () => []] = useState([]);
    const { offices } = useContext(OfficesContext);
    const id = currentUser.data.oid;
    const pos = offices.map(a => a.id).indexOf(id);

    const { name, short } = offices[pos];

    useEffect(() => {
        const getUsers = async () => {
            const docs = await getUsersByOffice(id);
            setUsers(docs);
        }
        getUsers();
    }, []);

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold mt-6 has-text-white'>{short}</h2>
                    <p className='mb-6 has-text-white'>{name}</p>
                        <div className="block pb-6">
                        <div className='columns is-multiline mb-6'>
                            {employment.map((emp) => {
                                return  <AdminDashboardCard office={id} key={emp.id} employment={emp.text} additionalClasses="column is-3"/>
                            })}
                        </div>

                            {users.map((user) => {
                                    return <EmployeeItem key={user.id} user={user}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Employees;