import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { createLog, updateUserDocument } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const OtherInformation = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState({skills: currentUser["data"]["skills"] ? currentUser["data"]["skills"] : [],
    recognition: currentUser["data"]["recognition"] ? currentUser["data"]["recognition"] : [], membership: currentUser["data"]["membership"] ? currentUser["data"]["membership"] : []});
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const { skills, recognition, membership } = formFields;

    const handleChange = (object, index, event) => {
        const { id, value } = event.target;
        object[index] = value;

        setFormFields({...formFields});
    }

    const handleOnAdd = (object) => {
        object.push("");

        setFormFields({...formFields});
    }

    const handleOnRemove = (object, index) => {
        object.splice(index, 1);

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await updateUserDocument(currentUser["uid"], formFields);
        if (response === "success") {
            const log = {"action": "User updated PDS other information", "by" : currentUser.uid}
            await createLog(log);

            Object.assign(currentUser["data"], formFields);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Other Information</h2>
                    <Tabs className="block" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="tabs is-toggle">
        <TabList>
            <Tab className={tabIndex === 0 ? "is-active" : ""}><a>Skills/Hobbies</a></Tab>
            <Tab className={tabIndex === 1 ? "is-active" : ""}><a>Recognition</a></Tab>
            <Tab className={tabIndex === 2 ? "is-active" : ""}><a>Membership</a></Tab>
            </TabList>
        </div>
            <TabPanel>
            <form onSubmit={handleSubmit}>
                        {skills.map((item, index) => {
                            return (
                                <div className='columns is-vcentered' key={index}>
                                    <FormInput type="text" required id="title" value={item} onChange={(e) => handleChange(skills, index, e)} label="Special Skills/Hobbies" additionalClasses="column is-10"/>
                                    <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(skills, index)} className="delete is-large"></button></div>
                                </div>
                            )
                        })}
                        <Button additionalClasses="block mr-4" type="button" onClick={() => handleOnAdd(skills)}>Add Skill/Hobby</Button>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </TabPanel>
                
            <TabPanel>
            <form onSubmit={handleSubmit}>
                        {recognition.map((item, index) => {
                            return (
                                <div className='columns is-vcentered' key={index}>
                                    <FormInput type="text" required id="title" value={item} onChange={(e) => handleChange(recognition, index, e)} label="Non-Academic Distinctions/Recognition" additionalClasses="column is-10"/>
                                    <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(recognition, index)} className="delete is-large"></button></div>
                                </div>
                            )
                        })}
                        <Button additionalClasses="block mr-4" type="button" onClick={() => handleOnAdd(recognition)}>Add Recognition</Button>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </TabPanel>

            <TabPanel>
            <form onSubmit={handleSubmit}>
                        {membership.map((item, index) => {
                            return (
                                <div className='columns is-vcentered' key={index}>
                                    <FormInput type="text" required id="title" value={item} onChange={(e) => handleChange(membership, index, e)} label="Membership in Association/Organization" additionalClasses="column is-10"/>
                                    <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(membership, index)} className="delete is-large"></button></div>
                                </div>
                            )
                        })}
                        <Button additionalClasses="block mr-4" type="button" onClick={() => handleOnAdd(membership)}>Add Membership</Button>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </TabPanel>
                </Tabs>
                </div>
            </div>
        </div>
    )
}

export default OtherInformation;