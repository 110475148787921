import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import FormTextArea from '../components/form-textarea';
import { createTravelNotification, createTravelOrder } from '../utils/firebase';
import { searchUsers } from '../utils/firebase';
import SearchItem from '../components/search-item';
import FormSelect from '../components/form-select';

const defaultFormFields = {
    destination: '',
    departure: '',
    arrival: '',
    purpose: '',
    status: 'For Approval',
    chief: '',
    designation: '',
    type: 'Select',
    officialTime: 'Select',
    plate: '',
    participants: []
}

const NewTravelOrder = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const pos = offices.map(a => a.id).indexOf(currentUser.data.oid);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const [users, setUsers = () => []] = useState([]);
    const [search, setSearch] = useState("");
    const { destination, departure, arrival, purpose, participants, type, officialTime, plate } = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    useEffect(() => {
        const getUsers = async () => {
            const s = search.toLowerCase().trim().split(" ");
            const docs = await searchUsers(s, participants);
            setUsers(docs);
        }
        getUsers();
    }, [search]);

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (participants.length === 0) {
            alert("Add participants");
            return;
        } else if (type === "Select") {
            alert("Select type");
            return;
        } else if (officialTime === "Select") {
            alert("Select if official time");
            return;
        }

        const ids = participants.map(a => a.id);
        formFields["ids"] = ids;
        formFields["oid"] = currentUser.data.oid;
        if (offices[pos].designation) {
            formFields["designation"] = offices[pos].designation;
        }
        if (offices[pos].chief) {
            formFields["chief"] = offices[pos].chief;
        }

        const resp = await createTravelOrder(formFields);
        if (resp.includes("error")) {
            alert(resp);
            return;
        }

        const res = await createTravelNotification(resp, participants, arrival);
        if (res === "success") {
            navigate("/travel-orders/" + resp);
            return;
        }

        alert(res);
    }

    const onAddHandler = async(user) => {
        const pos = participants.map(a => a.id).indexOf(user.id);

        if (pos !== -1) {
            return;
        }

        setSearch("");
        setFormFields({...formFields, participants: [...participants, user]});
    }

    const onRemoveHandler = async(userToRemove) => {
        setFormFields({...formFields, participants: participants.filter(user => user.id !== userToRemove.id)});
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Travel Order</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                    <div className='columns is-multiline'>
                                    <div className='custom-dropdown column is-12'>
                                    <label>Participants</label>
                                    <div className='custom-input'>
                                    {participants.map((user) => {
                                                    return <SearchItem key={user.id} user={user} buttonText="Remove" onClickHandler={onRemoveHandler}/>
                                                    })
                                                }
                                                <input autoComplete="off" type="text" id="search" value={search} onChange={(e) => {setSearch(e.target.value)}} />
                                    </div>
                                        {search ? users.length > 0 ?
                                            <div className="custom-dropdown-menu">
                                                <div className='custom-dropdown-content'>
                                                {users.map((user, index) => {
                                                    const pos = offices.map(a => a.id).indexOf(user.oid);
                                                        return <SearchItem tabIndex={index} key={user.id} office={pos > -1 ? offices[pos] : null} user={user} buttonText="Add" onClickHandler={onAddHandler} additionalClasses="column is-12"/>
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            : 
                                            <div className="custom-dropdown-menu">
                                                <div className='custom-dropdown-content has-text-centered py-5'>
                                                    No results found
                                                    </div>
                                                </div>
                                            : ""}
                                        </div>

                                        <FormInput type="text" required id="destination" value={destination} onChange={handleChange} label="Place of Destination" additionalClasses="column is-12"/>
                                        <FormSelect options={["With Claim", "Without Claim"]} type="text" required id="type" value={type} onChange={handleChange} label="Type" additionalClasses="column is-6"/>
                                        <FormSelect options={["Yes", "No"]} type="text" required id="officialTime" value={officialTime} onChange={handleChange} label="Authorized to Use Government Vehicle?" additionalClasses={`column ${officialTime === "Yes" ? "is-2" : "is-6"}`}/>
                                        {officialTime === "Yes" ? <FormInput type="text" required id="plate" value={plate} onChange={handleChange} label="Plate No." additionalClasses="column is-4"/> : ""}
                                        <FormInput type="date" required id="departure" value={departure} onChange={handleChange} label="Date of Departure" additionalClasses="column is-6"/>
                                        <FormInput type="date" required id="arrival" value={arrival} onChange={handleChange} label="Date of Arrival" additionalClasses="column is-6"/>
                                        
                                        <FormTextArea type="text" required id="purpose" value={purpose} onChange={handleChange} label="Purpose" additionalClasses="column is-12"/>
                                    </div>
                                    <Button additionalClasses="block is-success" type="submit">Submit</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewTravelOrder;