import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { createLog, updateUserDocument, uploadFile } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';
import FormSelect from '../../components/form-select';

const defaultFormField = {
    "photoFile": null,
    "drugTestFile": null,
    "cedulaFile": null,
    "nbiCertificateFile": null,
    "medicalCertificateFile": null,
    "photoFileName": "",
    "drugTestFileName": "",
    "cedulaFileName": "",
    "nbiCertificateFileName": "",
    "medicalCertificateFileName": "",
    "photoFileUrl": "",
    "drugTestFileUrl": "",
    "cedulaFileUrl": "",
    "nbiCertificateFileUrl": "",
    "medicalCertificateFileUrl": "",
}

const FileUpload = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState(currentUser.data.files ? currentUser.data.files : defaultFormField);
    const navigate = useNavigate();
    const {drugTestFile, drugTestFileName, cedulaFile, cedulaFileName, nbiCertificateFile, nbiCertificateFileName, medicalCertificateFile, 
        medicalCertificateFileName, photoFile, photoFileName, photoFileUrl} = formFields;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (drugTestFile === null && cedulaFile === null && nbiCertificateFile === null && medicalCertificateFile === null && photoFile === null) {
            alert("Nothing to upload");
            return;
        }

        if (photoFile) {
            formFields["photoFileName"] = photoFile.name;
            formFields["photoFileUrl"] = await uploadFile(currentUser.uid, photoFile);
        }
        if (drugTestFile) {
            formFields["drugTestFileName"] = drugTestFile.name;
            formFields["drugTestFileUrl"] = await uploadFile(currentUser.uid, drugTestFile);
        }
        if (cedulaFile) {
            formFields["cedulaFileName"] = cedulaFile.name;
            formFields["cedulaFileUrl"] = await uploadFile(currentUser.uid, cedulaFile);
        }
        if (nbiCertificateFile) {
            formFields["nbiCertificateFileName"] = nbiCertificateFile.name;
            formFields["nbiCertificateFileUrl"] = await uploadFile(currentUser.uid, nbiCertificateFile);
        }
        if (medicalCertificateFile) {
            formFields["medicalCertificateFileName"] = medicalCertificateFile.name;
            formFields["medicalCertificateFileUrl"] = await uploadFile(currentUser.uid, medicalCertificateFile);
        }

        delete formFields["photoFile"];
        delete formFields["drugTestFile"];
        delete formFields["cedulaFile"];
        delete formFields["nbiCertificateFile"];
        delete formFields["medicalCertificateFile"];

        const response = await updateUserDocument(currentUser.uid, {"files": formFields});
        if (response === "success") {
            const log = {"action": "User uploaded files", "by" : currentUser.uid}
            await createLog(log);

            currentUser["data"]["files"] = formFields;
            Object.assign(currentUser["data"]["files"], currentUser.data.files);
        }
        alert(response);
    }

    const handleChange = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }

        setFormFields({...formFields, [id]: files[0]})
    }


    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>File Upload</h2>
                    <form onSubmit={handleSubmit}>
                    
                    <label>Photo</label>
                    <div className="file has-name is-fullwidth mb-5">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={handleChange} id='photoFile'
                            accept= "image/*"/>
                            <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Choose a file…
                            </span>
                            </span>
                            <span className="file-name">
                                {photoFile ? photoFile.name : photoFileName}
                            </span>
                        </label>
                    </div>
                    
                    <label>Drug Test</label>
                    <div className="file has-name is-fullwidth mb-5">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={handleChange} id='drugTestFile'
                            accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                            <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Choose a file…
                            </span>
                            </span>
                            <span className="file-name">
                                {drugTestFile ? drugTestFile.name : drugTestFileName}
                            </span>
                        </label>
                    </div>

                    <label>Cedula</label>
                    <div className="file has-name is-fullwidth mb-5">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={handleChange} id='cedulaFile'
                            accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                            <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Choose a file…
                            </span>
                            </span>
                            <span className="file-name">
                                {cedulaFile ? cedulaFile.name : cedulaFileName}
                            </span>
                        </label>
                    </div>

                    <label>NBI Certificate</label>
                    <div className="file has-name is-fullwidth mb-5">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={handleChange} id='nbiCertificateFile'
                            accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                            <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Choose a file…
                            </span>
                            </span>
                            <span className="file-name">
                                {nbiCertificateFile ? nbiCertificateFile.name : nbiCertificateFileName}
                            </span>
                        </label>
                    </div>

                    <label>Medical Certificate</label>
                    <div className="file has-name is-fullwidth mb-5">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={handleChange} id='medicalCertificateFile'
                            accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                            <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Choose a file…
                            </span>
                            </span>
                            <span className="file-name">
                                {medicalCertificateFile ? medicalCertificateFile.name : medicalCertificateFileName}
                            </span>
                        </label>
                    </div>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FileUpload;