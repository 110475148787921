import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { useLocation } from 'react-router-dom'
import { createLeaveCredit, getLeaveApplication, getUserDocument, updateLeaveDocument } from '../utils/firebase';
import './travel-order.scss'
import Button from '../components/button';
import { OfficesContext } from '../context/offices-context';
import leaveForm from '../assets/leave-form.xlsx'
import ExcelJS from 'exceljs';
import { format } from "date-fns";

const defaultFormField = {
    status: "",
    type: "",
    from: "",
    to: "",
    specify: "",
    details: "",
    commutation: ""
}

const defaultField = {
    firstName: "",
    middleName: "",
    surname: "",
    oid: "",
}

const LeaveApplication = () => {
    const { currentUser } = useContext(UserContext);
    const location = useLocation();
    const [leave, setLeave = () => []] = useState(defaultFormField);
    const [user, setUser = () => []] = useState(defaultField);
    const {status, type, from, to, specify, details, commutation, uid, created} = leave;
    const { offices } = useContext(OfficesContext);
    const {firstName, middleName, surname, oid, designation} = user;
    const pos = offices.map(a => a.id).indexOf(oid);

    const id = location.pathname.replace("/leave-applications/", "");
    useEffect(() => {
        const getTO = async () => {
            const doc = await getLeaveApplication(id);
            setLeave(doc);
        }
        getTO();
    }, []);

    useEffect(() => {
        const getTO = async () => {
            const doc = await getUserDocument(uid);
            setUser(doc);
        }
        getTO();
    }, [leave]);

    const admin = currentUser.data.type === "admin";
    const goadmin = currentUser.data.type === "go-admin";
    const superadmin = currentUser.data.type === "superadmin";

    const f = new Date(from);
    const t = new Date(to);
    const dif = Math.floor((t.getTime() - f.getTime()) / 86400000) + 1;

    const onClickHandler = async(approved) => {
        var stat;
        if (approved) {
            if (admin) {
                stat = "For GO Approval";
            }

            if (goadmin) {
                stat = "Approved";
            }

            if (superadmin) {
                stat = "For Approval";
            }
        } else {
            stat = "Disapproved";
        }
        
        const response = await updateLeaveDocument(id, {"status": stat});
        if (response !== "success") {
            alert(response);
            return;
        }

        if (goadmin) {
            const response = await createLeaveCredit(uid, id, {"value": -dif});
            if (response !== "success") {
                alert(response);
                return;
            }
        }

        setLeave({...leave, "status": stat});
    }

    const onPrintHandler = async() => {
        let blob = await fetch(leaveForm).then(r => r.blob());

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(blob);

        const sheet = workbook.getWorksheet(1);
        if (pos > -1) {
            sheet.getCell('A11').value = offices[pos].name;
        }
        sheet.getCell('G11').value = surname;
        sheet.getCell('M11').value = firstName;
        sheet.getCell('S11').value = middleName;
        sheet.getCell('A13').value = format(created.toDate(), "MM/dd/yyyy");
        // sheet.getCell('G13').value = designation ? designation : "";
        // sheet.getCell('P13').value = salary ? salary : "";
        switch (type) {
            case "Vacation Leave":
                sheet.getCell('B16').value = "✓";
                if (details === "Within the Philippines") {
                    sheet.getCell('M18').value = "✓";
                    sheet.getCell('R18').value = specify;
                } else {
                    sheet.getCell('M20').value = "✓";
                    sheet.getCell('Q20').value = specify;
                }
                break;
            case "Mandatory/Forced Leave":
                sheet.getCell('B18').value = "✓";
                break;
            case  "Sick Leave":
                sheet.getCell('B20').value = "✓";
                if (details === "In Hospital") {
                    sheet.getCell('M24').value = "✓";
                    sheet.getCell('S24').value = specify;
                } else {
                    sheet.getCell('M26').value = "✓";
                    sheet.getCell('S26').value = specify;
                }
                break;
            case  "Maternity Leave":
                sheet.getCell('B22').value = "✓";
                break;
            case  "Paternity Leave":
                sheet.getCell('B24').value = "✓";
                break;
            case "Special Privilege Leave":
                sheet.getCell('B26').value = "✓";
                break;
            case  "Solo Parent Leave":
                sheet.getCell('B28').value = "✓";
                break;
            case  "Study Leave":
                sheet.getCell('B30').value = "✓";
                if (details === "Completion of Master's Degree") {
                    sheet.getCell('M38').value = "✓";
                } else {
                    sheet.getCell('M40').value = "✓";
                }
                break;
            case  "10-Day VAWC Leave":
                sheet.getCell('B32').value = "✓";
                break;
            case  "Rehabilitation Privilege":
                sheet.getCell('B34').value = "✓";
                break;
            case "Special Leave Benefits for Women":
                sheet.getCell('B36').value = "✓";
                sheet.getCell('Q32').value = specify;
                break;
            case  "Special Emergency (Calamity) Leave":
                sheet.getCell('B38').value = "✓";
                break;
            case  "Adoption Leave":
                sheet.getCell('B40').value = "✓";
                break;
            case  "Others":
                if (details === "Monetization of Leave Credits") {
                    sheet.getCell('M44').value = "✓";
                } else {
                    sheet.getCell('M46').value = "✓";
                }
                break;
        }

        if (commutation === "Not Request") {
            sheet.getCell('M49').value = "✓";
        } else {
            sheet.getCell('M51').value = "✓";
        }

        const dat = format(f, "MM/dd/yyyy") + " - " + format(t, "MM/dd/yyyy");
        sheet.getCell('B53').value = dat;

        sheet.getCell('B49').value = dif + " days";

        const buffer = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(
          new Blob([buffer]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          firstName.toLowerCase() + '_' + surname.toLowerCase() + '_leave_form.xlsx',
        );
    
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='mt-6'><span className='is-size-2 has-text-weight-bold '>Leave Application</span>
                    {uid === currentUser.uid && status === "Approved" ? <Button additionalClasses="block is-pulled-right my-3" type="submit" onClick={onPrintHandler}>Download</Button> : ""}
                    {admin && status === "For Approval" || goadmin && status === "For GO Approval" || superadmin && status === "For HR Approval" ?
                    <div className='block is-pulled-right my-3'>
                        <Button additionalClasses="is-success mr-3" type="button" onClick={() => {onClickHandler(true)}}>Approve</Button>
                        <Button type="button" onClick={() => {onClickHandler(false)}}>Disapprove</Button>
                    </div>
                    : ""
                    }
                    </h2>
                    <p className='mb-6'><span className={`${status === "Approved" ? "is-success" : status === "Disapproved" ? "is-danger" : "is-warning"} tag`}>{status}</span></p>
                    

                    <div className='block pb-6 mt-6'>
                        <div className='columns is-multiline'>
                            <div className='column is-6'>
                                <label>Name</label>
                                <p className='is-size-5 has-text-weight-medium'>{surname + ", " + firstName} {middleName ? middleName.substring(0, 1) : ""}</p>
                            </div>
                            
                            {pos > -1 ?
                                <div className='column is-6'>
                                    <label>Office</label>
                                    <p className='is-size-5 has-text-weight-medium'>{offices[pos].name}</p>
                                </div>
                            : ""}

                            <div className='column is-6'>
                                <label>Type</label>
                                <p className='is-size-5 has-text-weight-medium'>{type}</p>
                            </div>

                            {details !== "Select" ? 
                            <div className='column is-6'>
                                <label>Details</label>
                                <p className='is-size-5 has-text-weight-medium'>{specify ? details + " - " + specify : details}</p>
                            </div> : ""}

                            <div className='column is-6'>
                                <label>Number of Days</label>
                                <p className='is-size-5 has-text-weight-medium'>{dif}</p>
                            </div>

                            <div className='column is-6'>
                                <label>From</label>
                                <p className='is-size-5 has-text-weight-medium'>{from}</p>
                            </div>

                            <div className='column is-6'>
                                <label>To</label>
                                <p className='is-size-5 has-text-weight-medium'>{to}</p>
                            </div>

                            <div className='column is-6'>
                                <label>Commutation</label>
                                <p className='is-size-5 has-text-weight-medium'>{commutation}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveApplication;