import './App.scss';
import { Routes, Route } from "react-router-dom";
import Navigation from "./routes/navigation/navigation";
import Dashboard from "./routes/dashboard";
import { useContext } from 'react';
import { UserContext } from './context/user-context';
import PersonalDataSheet from './routes/personal-data-sheet/personal-data-sheet';
import SignInForm from './routes/authentication/sign-in-form/sign-in-form';
import SignUpForm from './routes/authentication/sign-up-form/sign-up-form';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import PersonalInformation from './routes/personal-data-sheet/personal-information';
import FamilyBackground from './routes/personal-data-sheet/family-background';
import EducationalBackground from './routes/personal-data-sheet/educational-background';
import CivilServiceEligibility from './routes/personal-data-sheet/civil-service-eligibility';
import WorkExperience from './routes/personal-data-sheet/work-experience';
import VoluntaryWork from './routes/personal-data-sheet/voluntary-work';
import LearningDevelopment from './routes/personal-data-sheet/learning-development';
import OtherInformation from './routes/personal-data-sheet/other-information';
import AdminDashboard from './routes/admin-dashboard';
import Applicants from './routes/applicants';
import Employees from './routes/employees';
import Offices from './routes/offices';
import Office from './routes/office';
import NewOffice from './routes/new-office';
import Accounts from './routes/accounts';
import NewAccount from './routes/new-account';
import UpdateAccount from './routes/update-account';
import Unauthorized from './routes/unauthorized';
import TravelOrders from './routes/travel-orders';
import NewTravelOrder from './routes/new-travel-order';
import TravelOrder from './routes/travel-order';
import UpdateOffice from './routes/update-office';
import FileUpload from './routes/personal-data-sheet/file-upload';
import Contracts from './routes/contracts';
import NewContract from './routes/new-contract';
import ForgotPassword from './routes/authentication/forgot-password/forgot-password';
import ActivityLogs from './routes/activity-logs';
import ChangePassword from './routes/authentication/change-password/change-password';
import LeaveApplications from './routes/leave-applications';
import NewLeaveApplication from './routes/new-leave-application';
import LeaveApplication from './routes/leave-application';
import Contract from './routes/contract';
import Notifications from './routes/notifications';
import ServiceRecord from './routes/service-record';
import NewServiceRecord from './routes/new-service-record';
import UpdateServiceRecord from './routes/update-service-record';
import Update from './routes/update';
import UpdateUpdate from './routes/update-update';
import NewUpdate from './routes/new-update';
import NewLeaveCredits from './routes/new-leave-credits';
import IGHRSReport from './routes/ighrs-report';

function App() {
  const { currentUser } = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<Navigation/>}>
        <Route index element={currentUser ? currentUser.data.type === "admin" || currentUser.data.type === "go-admin" ? <Employees/> : currentUser.data.type === "superadmin" ? <AdminDashboard/> : <Dashboard/> : <SignInForm/>}/>
        <Route path="/notifications" element={currentUser ? <Notifications/> : <Unauthorized/>}/>
        <Route path="/change-password" element={currentUser ? <ChangePassword/> : <Unauthorized/>}/>
        <Route path="/pds" element={currentUser ? <PersonalDataSheet/> : <Unauthorized/>}/>
        <Route path="/pds/:id" element={currentUser ? <PersonalDataSheet/> : <Unauthorized/>}/>
        <Route path="/pds/file-upload" element={currentUser ? <FileUpload/> : <Unauthorized/>}/>
        <Route path="/pds/personal-information" element={currentUser ? <PersonalInformation/> : <Unauthorized/>}/>
        <Route path="/pds/family-background" element={currentUser ? <FamilyBackground/> : <Unauthorized/>}/>
        <Route path="/pds/educational-background" element={currentUser ? <EducationalBackground/> : <Unauthorized/>}/>
        <Route path="/pds/civil-service-eligibility" element={currentUser ? <CivilServiceEligibility/> : <Unauthorized/>}/>
        <Route path="/pds/work-experience" element={currentUser ? <WorkExperience/> : <Unauthorized/>}/>
        <Route path="/pds/voluntary-work" element={currentUser ? <VoluntaryWork/> : <Unauthorized/>}/>
        <Route path="/pds/learning-development" element={currentUser ? <LearningDevelopment/> : <Unauthorized/>}/>
        <Route path="/pds/other-information" element={currentUser ? <OtherInformation/> : <Unauthorized/>}/>
        <Route path="/signin" element={currentUser ? <Unauthorized/> : <SignInForm/>}/>
        <Route path="/signup" element={currentUser ? <Unauthorized/> : <SignUpForm/>}/>
        <Route path="/forgot-password" element={currentUser ? <Unauthorized/> : <ForgotPassword/>}/>
        <Route path="/activity-logs" element={currentUser ? currentUser.data.type === "superadmin" ? <ActivityLogs/> : <Unauthorized/> : ""}/>
        <Route path="/employees" element={currentUser ? currentUser.data.type === "superadmin" ? <Employees/> : <Unauthorized/> : ""}/>
        <Route path="/applicants" element={currentUser ? currentUser.data.type === "superadmin" ? <Applicants/> : <Unauthorized/> : ""}/>
        <Route path="/offices" element={currentUser ? currentUser.data.type === "superadmin" ? <Offices/> : <Unauthorized/> : ""}/>
        <Route path="/offices/new" element={currentUser ? currentUser.data.type === "superadmin" ? <NewOffice/> : <Unauthorized/> : ""}/>
        <Route path="/offices/:id" element={currentUser ? currentUser.data.type === "superadmin" ? <Office/> : <Unauthorized/> : ""}/>
        <Route path="/offices/:id/update" element={currentUser ? currentUser.data.type === "superadmin" ? <UpdateOffice/> : <Unauthorized/> : ""}/>
        <Route path="/accounts" element={currentUser ? currentUser.data.type === "superadmin" ? <Accounts/> : <Unauthorized/> : ""}/>
        <Route path="/accounts/new" element={currentUser ? currentUser.data.type === "superadmin" ? <NewAccount/> : <Unauthorized/> : ""}/>
        <Route path="/contracts" element={currentUser ? currentUser.data.type === "superadmin" ? <Contracts/> : <Unauthorized/> : ""}/>
        <Route path="/contracts/new" element={currentUser ? currentUser.data.type === "superadmin" ? <NewContract/> : <Unauthorized/> : ""}/>
        <Route path="/contracts/:id" element={currentUser ? currentUser.data.type === "superadmin" ? <Contract/> : <Unauthorized/> : ""}/>
        <Route path="/accounts/:id/update" element={currentUser ? currentUser.data.type === "superadmin" ? <UpdateAccount/> : <Unauthorized/> : ""}/>
        <Route path="/travel-orders" element={currentUser ? <TravelOrders/> : <Unauthorized/>}/>
        <Route path="/leave-applications" element={currentUser ? <LeaveApplications/> : <Unauthorized/>}/>
        <Route path="/leave-applications/new" element={currentUser ? <NewLeaveApplication/> : <Unauthorized/>}/>
        <Route path="/leave-applications/:id" element={currentUser ? <LeaveApplication/> : <Unauthorized/>}/>
        <Route path="/travel-orders/:id" element={currentUser ? <TravelOrder/> : <Unauthorized/>}/>
        <Route path="/travel-orders/new" element={currentUser ? currentUser.data.type === "admin" || currentUser.data.type === "go-admin" ? <NewTravelOrder/> : <Unauthorized/> : ""}/>
        <Route path="/service-record/:id" element={currentUser ? currentUser.data.type === "admin" || currentUser.data.type === "go-admin" ? <Unauthorized/> : <ServiceRecord/> : ""}/>
        <Route path="/service-record/:id/new" element={currentUser ? currentUser.data.type === "superadmin" ? <NewServiceRecord/> : <Unauthorized/> : ""}/>
        <Route path="/service-record/:uid/update/:id" element={currentUser ? currentUser.data.type === "superadmin" ? <UpdateServiceRecord/> : <Unauthorized/> : ""}/>
        <Route path="/news-updates/:id" element={currentUser ? <Update/> : <Unauthorized/>}/>
        <Route path="/news-updates/:id/update" element={currentUser ? currentUser.data.type === "superadmin" ? <UpdateUpdate/> : <Unauthorized/> : ""}/>
        <Route path="/news-updates/new" element={currentUser ? currentUser.data.type === "superadmin" ? <NewUpdate/> : <Unauthorized/> : ""}/>
        <Route path="/news-updates" element={currentUser ? currentUser.data.type === "user" ? <Unauthorized/> : <Dashboard/> : ""}/>
        <Route path="/leave-credits/:id" element={currentUser ? currentUser.data.type === "superadmin" ? <NewLeaveCredits/> : <Unauthorized/> : ""}/>
        <Route path="/ighrs-report" element={currentUser ? currentUser.data.type === "superadmin" ? <IGHRSReport/> : <Unauthorized/> : ""}/>
      </Route>
    </Routes>
  );
}

export default App;
