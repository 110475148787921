import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import FormSelect from '../../components/form-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../components/button';
import { createLog, updateUserDocument } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';

const defaultFormFields = {
    "birthDate" : "", "birthPlace" : "", "height" : "", "weight" : "", "gsisNumber" : "", "pagibigNumber" : "", "philhealthNumber" : "",
    "sssNumber" : "", "tinNumber" : "", "agencyNumber" : "", "houseNumber" : "", "street" : "", "village" : "", "barangay" : "", "municipality" : "",
    "province" : "", "zipCode" : "", "telephoneNumber" : "", "mobileNumber" : "", "bloodType" : "Select", "sex" : "Select", "civilStatus" : "Select",
    "citizenship" : "Select"
}

const PersonalInformation = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState({surname: currentUser["data"]["surname"], firstName: currentUser["data"]["firstName"], 
    middleName: currentUser["data"]["middleName"], nameExtension: currentUser["data"]["nameExtension"], emailAddress: currentUser["data"]["emailAddress"],
    personal: currentUser["data"]["personal"] ? currentUser["data"]["personal"] : defaultFormFields});
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    console.log(currentUser.data.personal);

    const { surname, firstName, middleName, nameExtension, emailAddress, personal } = formFields;

    const handleChange = (object, event) => {
        const { id, value } = event.target;
        if (object) {
            object[id] = value;
        } else {
            formFields[id] = value
        }

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const fullName = firstName + " " + middleName + " " + surname;
        const search = fullName.toLowerCase().split(" ");

        formFields["search"] = search;
        const response = await updateUserDocument(currentUser["uid"], formFields);
        if (response === "success") {
            const log = {"action": "User updated PDS personal information", "by" : currentUser.uid}
            await createLog(log);

            Object.assign(currentUser["data"], formFields);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Personal Information</h2>
        <Tabs className="block" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="tabs is-toggle">
        <TabList>
            <Tab className={tabIndex === 0 ? "is-active" : ""}><a>Personal</a></Tab>
            <Tab className={tabIndex === 1 ? "is-active" : ""}><a>Identification</a></Tab>
            <Tab className={tabIndex === 2 ? "is-active" : ""}><a>Contact & Address</a></Tab>
            </TabList>
        </div>
            <TabPanel>
            <form onSubmit={handleSubmit} className='custom-disable'>
                <div className='columns'>
                <FormInput disabled={currentUser.data.personal} type="text" required id="surname" value={surname} onChange={(e) => handleChange(null, e)} label="Surname" additionalClasses="column is-6"/>
                <FormInput disabled={currentUser.data.personal} type="text" required id="firstName" value={firstName} onChange={(e) => handleChange(null, e)} label="First Name" additionalClasses="column is-6"/>
            </div>
            
            <div className='columns'>
                <FormInput disabled={currentUser.data.personal} type="text" required id="middleName" value={middleName} onChange={(e) => handleChange(null, e)} label="Middle Name" additionalClasses="column is-6"/>
                <FormInput disabled={currentUser.data.personal} type="text" id="nameExtension" value={nameExtension} onChange={(e) => handleChange(null, e)} label="Name Extension" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
                <FormInput disabled={currentUser.data.personal} type="date" required id="birthDate" value={personal["birthDate"]} onChange={(e) => handleChange(personal, e)} label="Date of Birth" additionalClasses="column is-6"/>
                <FormInput disabled={currentUser.data.personal} type="text" required id="birthPlace" value={personal["birthPlace"]} onChange={(e) => handleChange(personal, e)} label="Place of Birth" additionalClasses="column is-6"/>
            </div>

            <div className='columns'>
            <FormSelect disabled={currentUser.data.personal} options={["Male", "Female", "Others"]} type="text" required id="sex" onChange={(e) => handleChange(personal, e)} value={personal["sex"]} label="Sex/Gender" additionalClasses="column is-6"/>
            <FormSelect disabled={currentUser.data.personal} options={["Single", "Married", "Widowed", "Separated", "Others"]} type="text" required id="civilStatus" onChange={(e) => handleChange(personal, e)} value={personal["civilStatus"]} label="Civil Status" additionalClasses="column is-6"/>
                </div>
            
            <div className='columns'>
            <FormInput type="number" required id="height" value={personal["height"]} onChange={(e) => handleChange(personal, e)} label="Height (m)" additionalClasses="column is-6"/>
            <FormInput type="number" required id="weight" value={personal["weight"]} onChange={(e) => handleChange(personal, e)} label="Weight (kg)" additionalClasses="column is-6"/>
        </div>
            
        <div className='columns'>
            <FormSelect options={["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]} type="text" required id="bloodType" onChange={(e) => handleChange(personal, e)} value={personal["bloodType"]} label="Blood Type" additionalClasses="column is-6"/>
        </div>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
            </TabPanel>
            <TabPanel>
                
            <form onSubmit={handleSubmit}>
            <div className='columns'>
            <FormInput type="text" required id="gsisNumber" value={personal["gsisNumber"]} onChange={(e) => handleChange(personal, e)} label="GSIS ID No." additionalClasses="column is-6"/>
        <FormInput type="text" required id="pagibigNumber" value={personal["pagibigNumber"]} onChange={(e) => handleChange(personal, e)} label="Pag-Ibig ID No." additionalClasses="column is-6"/>
        </div>
        <div className='columns'>
            <FormInput type="text" required id="philhealthNumber" value={personal["philhealthNumber"]} onChange={(e) => handleChange(personal, e)} label="Philhealth ID No." additionalClasses="column is-6"/>
            <FormInput type="text" required id="sssNumber" value={personal["sssNumber"]} onChange={(e) => handleChange(personal, e)} label="SSS No." additionalClasses="column is-6"/>
        </div>
        <div className='columns'>
        <FormInput type="text" required id="tinNumber" value={personal["tinNumber"]} onChange={(e) => handleChange(personal, e)} label="TIN No." additionalClasses="column is-6"/>
            <FormInput type="text" required id="agencyNumber" value={personal["agencyNumber"]} onChange={(e) => handleChange(personal, e)} label="Agency Employee No." additionalClasses="column is-6"/>
        </div>
        <div className='columns'>
        <FormSelect options={["Filipino", "Dual Citizenship"]} type="text" required id="citizenship" onChange={(e) => handleChange(personal, e)} value={personal["citizenship"]} label="Citizenship" additionalClasses="column is-6"/>
        </div>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
            </TabPanel>

            <TabPanel>
            <form onSubmit={handleSubmit}>
        <div className='columns'>
            <FormInput type="text" id="houseNumber" value={personal["houseNumber"]} onChange={(e) => handleChange(personal, e)} label="House/Block/Lot No." additionalClasses="column is-6"/>
        <FormInput type="text" id="street" value={personal["street"]} onChange={(e) => handleChange(personal, e)} label="Street" additionalClasses="column is-6"/>
        </div>
        
        <div className='columns'>
            <FormInput type="text" id="village" value={personal["village"]} onChange={(e) => handleChange(personal, e)} label="Subdivion/Village" additionalClasses="column is-6"/>
            <FormInput type="text" required id="barangay" value={personal["barangay"]} onChange={(e) => handleChange(personal, e)} label="Barangay" additionalClasses="column is-6"/>
        </div>
        <div className='columns'>
        <FormInput type="text" required id="municipality" value={personal["municipality"]} onChange={(e) => handleChange(personal, e)} label="Municipality" additionalClasses="column is-6"/>
            <FormInput type="text" required id="province" value={personal["province"]} onChange={(e) => handleChange(personal, e)} label="Province" additionalClasses="column is-6"/>
        </div>
        
        <div className='columns'>
            <FormInput type="number" required id="zipCode" value={personal["zipCode"]} onChange={(e) => handleChange(personal, e)} label="Zip Code" additionalClasses="column is-6"/>
            <FormInput type="email" required id="emailAddress" value={emailAddress} onChange={(e) => handleChange(null, e)} label="Email Address" additionalClasses="column is-6"/>
        </div>

        <div className='columns'>
        <FormInput type="tel" id="telephoneNumber" value={personal["telephoneNumber"]} onChange={(e) => handleChange(personal, e)} label="Telephone No." additionalClasses="column is-6"/>
            <FormInput type="tel" id="mobileNumber" value={personal["mobileNumber"]} onChange={(e) => handleChange(personal, e)} label="Mobile No." additionalClasses="column is-6"/>
        </div>
        <Button additionalClasses="block is-success" type="submit">Submit</Button>
        </form>
            </TabPanel>
        </Tabs>
        
                </div>
            </div>
        </div>
    )
}

export default PersonalInformation;