import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { useLocation } from 'react-router-dom'
import { OfficesContext } from '../context/offices-context';
import { createLog, downloadFile, getTravelOrder, updateTravelOrderDocument, updateUserNotifications, uploadTravelReport } from '../utils/firebase';
import seal from '../assets/seal.png'
import pagasa from '../assets/pagasa.png'
import './travel-order.scss'
import html2canvas from 'html2canvas';
import { format } from "date-fns";
import Button from '../components/button';
import { Fragment } from 'react';
import eSig from '../assets/e-sig.jpg'
import eSig2 from '../assets/e-sig-2.jpg'

const defaultFormField = {
    "ids": [],
    "participants": [],
    "purpose": "",
    "arrival": "2023-05-05",
    "departure": "2023-05-05",
    "destination": "",
    "created": null,
    "chief": "",
    "designation": "",
    "status": "",
    "type": "",
    "oid": "",
    "fileName": "",
    "fileUrl": "",
}

const TravelOrder = () => {
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const location = useLocation();
    const [travelOrder, setTravelOrder = () => []] = useState(defaultFormField);
    const [file, setFile] = useState(null);

    const id = location.pathname.replace("/travel-orders/", "");
    useEffect(() => {
        const getTO = async () => {
            const doc = await getTravelOrder(id);
            setTravelOrder(doc);
        }
        getTO();
    }, []);

    const { participants, purpose, arrival, departure, destination, created, chief, designation, status, type, fileName, ids, plate, fileUrl } = travelOrder;
    const pos = offices.map(a => a.id).indexOf(travelOrder.oid);

    const onPrintHandler = () => {
        var printContents = document.getElementById("print");
    
        html2canvas(printContents, {scale: 3}).then(function(canvas) {
          var myImage = canvas.toDataURL('image/png');
    
          var mywindow = window.open('PRINT');
    
          mywindow.document.write('<html><head><title>' + document.title  + '</title>');
          mywindow.document.write('</head><body >');
          mywindow.document.write('<img src="' + myImage + '" style="width: 100%"/>');
          mywindow.document.write('</body></html>');
      
          setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
        
            mywindow.print();
            mywindow.close();
          }, 10)
        })
      }

      const onClickHandler = async(approve) => {
        var status = "Approved";
        if (!approve) {
            status = "Disapproved";
        }
        const res = await updateTravelOrderDocument(id, {status});
        if (res === "success") {
            setTravelOrder({...travelOrder, status: status});
        } else {
            alert(res);
        }
      }

      var text;
      if (type === "With Claim") {
        text = "By way of this directive you are entitled to collect per diems/allowances of your actual travel pursuant to Executive Order No. 77 / DILG Memorandum Circular 2019415. Authorized to use government vehicle with Plate No. SEH-734 for the aforesaid purpose.";
      } else {
        text = "Official Time Only";
      }

      const handleSubmit = async (event) => {
        event.preventDefault();

        if (file === null) {
            alert("Select a file");
        }

        const up = await uploadTravelReport(file, travelOrder.id);
        if (up.includes("error")) {
            alert(up);
            return;
        }

        const resp = await updateTravelOrderDocument(id, {"fileUrl": up, "fileName": file.name})
        if (resp === "success") {
            const res = await updateUserNotifications(travelOrder.id, ids, {"submitted": true});
            if (res === "success") {
                const log = {"action": "User submitted travel order report", "by" : currentUser.uid}
                await createLog(log);
                return;
            }
    
            alert(res);
            return;
        }
        alert(resp);
    }
    

    const handleUpload = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }
        setFile(files[0]);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold mt-6'>Travel Order
                    {
                    currentUser.data.type !== "user" && status === "Approved" && travelOrder.oid === currentUser.data.oid ?
                    <div className='is-pulled-right'>
                        {fileName && fileUrl ? 
                        <Button additionalClasses="block my-3 mr-3" type="button" 
                        onClick={() => downloadFile(fileName, fileUrl)}>Download</Button> : ""}
                        <Button additionalClasses="block is-success my-3" type="button" onClick={onPrintHandler}>Print</Button>
                    </div>
                    : currentUser.data.type === "go-admin" && status === "For Approval" ?
                    <div className='block is-pulled-right my-3'>
                        <Button additionalClasses="is-success mr-3" type="button" onClick={() => {onClickHandler(true)}}>Approve</Button>
                        <Button type="button" onClick={() => {onClickHandler(false)}}>Disapprove</Button>
                    </div>
                    : currentUser.data.type === "superadmin" && fileName && fileUrl ? <Button additionalClasses="block is-pulled-right my-3" type="button" 
                        onClick={() => downloadFile(fileName, fileUrl)}>Download</Button>
                    : ""
                    }</h2>
                    <p className='mb-6'><span className={`${status === "For Approval" ? "is-warning" : status === "Approved" ? "is-success" : status === "Disapproved" ? "is-danger" : ""} tag`}>{status}</span></p>
                        
                        {currentUser.data.type === "user" && status === "Approved" && new Date(arrival) < new Date() ?
                        <form onSubmit={handleSubmit}>
                        <div className='columns'>
                            <div className='column is-12'>
                                <label>File Upload</label>
                                <div className="file has-name is-fullwidth">
                                    <label className="file-label">
                                        <input required className="file-input" type="file" onChange={handleUpload} id='contract'
                                        accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                                        <span className="file-cta">
                                        <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                        </span>
                                        <span className="file-label">
                                            Choose a file…
                                        </span>
                                        </span>
                                        <span className="file-name">
                                            {file ? file.name : fileName}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                            <Button additionalClasses="block is-success mb-6" type="submit">Submit</Button>
                        </form>
                        : ""}
                            

                        <div className="block pb-6">
                                <div id='print'>
                                <div className='columns is-vcentered has-text-right'>
                                    <div className='column is-4'>
                                        <img src={seal}/>
                                    </div>

                                    <div className='column is-4 has-text-centered'>
                                    <p>Republic of the Philippines</p>
                                    <p>Region V - Bicol</p>
                                    <p>Province of Camarines Norte</p>
                                    </div>
                                    
                                    <div className='column is-4 has-text-left'>
                                        <img src={pagasa}/>
                                    </div>
                                </div>
                                
                                <p className='is-size-5 has-text-weight-semibold has-text-centered is-uppercase'>{pos > -1 ? offices[pos].name : ""}</p>
                                <p className='mt-5 is-size-3 has-text-weight-semibold has-text-centered'>Travel Order</p>

                                <div className='columns mt-2'>
                                    <div className='column is-11 has-text-right'>{created ? format(created.toDate(), "MMMM dd, yyyy") : ""}</div>
                                </div>
                                
                                <div className='columns mt-4'>
                                    <div className='column is-1 is-offset-1'>TO:</div>
                                    <div className='column'>{participants.map((part) => {
                                        const {middleName, surname, firstName} = part;
                                        const name = middleName ? firstName + " " + middleName.substring(0, 1) + ". "  + surname: firstName + " " + surname;
                                        
                                        return <p className='has-text-weight-semibold' key={part.id}>{name}</p>;
                                    })}</div>
                                </div>

                                <p className='has-text-centered mt-4'>You are hereby directed to undertake the following itinerary of travel.</p>

                                <div className='columns is-multiline mt-2 is-gapless'>
                                    <div className='column is-3'>Place of Destination:</div>
                                    <div className='column is-9'>{destination}</div>
                                    
                                    <div className='column is-3'>Date of Departure:</div>
                                    <div className='column is-9'>{format(new Date(departure), "MMMM dd, yyyy")}</div>
                                    
                                    <div className='column is-3'>Date of Arrival:</div>
                                    <div className='column is-9'>{format(new Date(arrival), "MMMM dd, yyyy")}</div>
                                    
                                    <div className='column is-3'>Purpose:</div>
                                    <div className='column is-9'>{purpose}</div>
                                </div>

                                <div className='columns mt-4'>
                                    <div className='column is-1 has-text-right'>
                                    <input type="checkbox" defaultChecked/>
                                    </div>
                                    <div className='column is-11 is-size-6'>
                                    {text}

                                    {plate ? <Fragment><br/><br/>Authorized to use government vehicle with plate no. {plate}</Fragment> : ""}
                                    </div>
                                </div>

                                <div className='columns mt-4 is-multiline'>
                                    <div className='column is-6 is-offset-6'>Recommending Approval:</div>
                                    <div className='column is-6 is-offset-6 has-text-centered is-paddingless has-text-weight-semibold mt-5'>{status === "Approved" ? <Fragment><img width="200px" src={eSig2}/><br/></Fragment> : ""}{chief ? chief : "* Chief Name"}</div>
                                    <div className='column is-6 is-offset-6 has-text-centered is-paddingless'>{designation ? designation : "* Designation"}</div>
                                </div>

                                {/* <div className='columns mt-4 is-multiline'>
                                    <div className='column is-6'>Approved:</div>
                                    <div className='column is-6'>For and in absence of the Governer:</div>
                                    
                                    <div className='column is-6 has-text-centered is-paddingless has-text-weight-semibold mt-5'>* Governor Name</div>
                                    <div className='column is-6 has-text-centered is-paddingless has-text-weight-semibold mt-5'>* Vice Governor Name</div>

                                    <div className='column is-6 has-text-centered is-paddingless'>Governor</div>
                                    <div className='column is-6 has-text-centered is-paddingless'>Vice Governor</div>
                                </div> */}

                                <div className='columns mt-4 is-multiline'>
                                    <div className='column is-6 is-offset-6'>Approved:</div>
                                    
                                    <div className='column is-6 is-offset-6 has-text-centered is-paddingless has-text-weight-semibold mt-5'>{status === "Approved" ? <Fragment><img width="200px" src={eSig}/><br/></Fragment> : ""}* Governor Name</div>

                                    <div className='column is-6 is-offset-6 has-text-centered is-paddingless'>Governor</div>
                                </div>

                                <table className='mt-6'>
                                    <tbody>
                                        <tr className='has-text-centered has-text-weight-semibold'>
                                            <td>Date</td>
                                            <td>Place Visited</td>
                                            <td>Accomplishment</td>
                                            <td>Certifying Officer</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                                </div>
                    </div>
                </div>
            </div>
    )
}

export default TravelOrder;