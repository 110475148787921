import { createContext, useState, useEffect } from "react";
import { getNotifications } from "../utils/firebase";
import { useContext } from "react";
import { UserContext } from "./user-context";

export const NotificationsContext = createContext({
    notifications: [],
    setNotifications: () => null,
    updateNotificationInContext: () => {},
});

const updateOffice = (notifications, notificationToUpdate) => {
    return notifications.map((notification) => notification.id === notificationToUpdate.id ? {...notificationToUpdate} : notification);
}

export const NotificationsProvider = ({ children }) => {
    const { currentUser } = useContext(UserContext);
    const [notifications, setNotifications] = useState([]);

    const updateNotificationInContext = (notification) => {
        setNotifications(updateOffice(notifications, notification));
    }

    const value = {notifications, setNotifications, updateNotificationInContext};

    useEffect(() => {
        const getResponse = async () => {
            const docs = await getNotifications(currentUser.data.type, currentUser.data.oid, currentUser.uid);
            setNotifications(docs);
        }

        if (currentUser && currentUser.data) {
            getResponse();
        }
    }, [currentUser]);

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
} 