import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { createLog, updateUserDocument } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';
import FormSelect from '../../components/form-select';

const EducationalBackground = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState({education: currentUser["data"]["education"] ? currentUser["data"]["education"] : []});
    const navigate = useNavigate();
    const { education } = formFields;

    const handleChange = (index, event) => {
        const { id, value } = event.target;
        education[index][id] = value;

        setFormFields({...formFields});
    }

    const handleOnAdd = () => {
        education.push({
            "level" : "Select",
            "schoolName" : "",
            "course": "",
            "from": "",
            "to": "",
            "unitsEarned": "",
            "year": "",
            "honorsReceived": ""
        });

        setFormFields({...formFields});
    }

    const handleOnRemove = (index) => {
        education.splice(index, 1);

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await updateUserDocument(currentUser["uid"], formFields);
        if (response === "success") {
            const log = {"action": "User updated PDS educational background", "by" : currentUser.uid}
            await createLog(log);

            Object.assign(currentUser["data"], formFields);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Educational Background</h2>
                    <form onSubmit={handleSubmit}>
                        {education.map((edu, index) => {
                            return (
                                <div className='block' key={index}>
                                    <div className='columns is-vcentered'>
                                        <FormSelect options={["Elementary", "Secondary", "Vocational/Trade Course", "College", "Graduate Studies"]} type="text" required id="level" onChange={(e) => handleChange(index, e)} value={edu["level"]} label="Level" additionalClasses="column is-5"/>
                                        <FormInput type="text" required id="schoolName" value={edu["schoolName"]} onChange={(e) => handleChange(index, e)} label="School Name" additionalClasses="column is-5"/>
                                        <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(index)} className="delete is-large"></button></div>
                                    </div>
                                    
                                    <div className='columns'>
                                        <FormInput type="text" required id="course" value={edu["course"]} onChange={(e) => handleChange(index, e)} label="Basic Education/Degree/Course" additionalClasses="column is-5"/>
                                        <FormInput type="text" id="unitsEarned" value={edu["unitsEarned"]} onChange={(e) => handleChange(index, e)} label="Highest Level/Units Earned" additionalClasses="column is-5"/>
                                    </div>
                                    <div className='columns'>
                                    <FormInput type="date" required id="from" value={edu["from"]} onChange={(e) => handleChange(index, e)} label="From" additionalClasses="column is-5"/>
                                        <FormInput type="date" required id="to" value={edu["to"]} onChange={(e) => handleChange(index, e)} label="To" additionalClasses="column is-5"/>
                                    </div>
                                    
                                    <div className='columns'>
                                        <FormInput type="number"  min="1900" max="2099" step="1" id="year" value={edu["year"]} onChange={(e) => handleChange(index, e)} label="Year Graduated" additionalClasses="column is-5"/>
                                        <FormInput type="text" id="honorsReceived" value={edu["honorsReceived"]} onChange={(e) => handleChange(index, e)} label="Scholarship/Academic Honors Received" additionalClasses="column is-5"/>
                                    </div>
                                </div>
                            )
                        })}
                        <Button additionalClasses="block mr-4" type="button" onClick={handleOnAdd}>Add Education</Button>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EducationalBackground;