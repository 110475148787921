import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import TravelItem from '../components/travel-item';
import { getLeaveApplicationsByOffice, getUserLeaveApplications, getLeaveApplications, getApprovedLeaveApplications, getLeaveCredits } from '../utils/firebase';
import { OfficesContext } from '../context/offices-context';
import {useNavigate} from 'react-router-dom';
import Button from '../components/button';
import LeaveItem from '../components/leave-item';
import { format } from "date-fns";

const LeaveApplications = () => {
    const { currentUser } = useContext(UserContext);
    const [travelOrders, setTravelOrders = () => []] = useState([]);
    const [leaveCredits, setLeaveCredits = () => []] = useState([]);
    const { offices } = useContext(OfficesContext);
    const navigate = useNavigate();

    const admin = currentUser.data.type === "admin";
    const goadmin = currentUser.data.type === "go-admin";
    const superadmin = currentUser.data.type === "superadmin";
    const onClickHandler = () => {
        navigate("/leave-applications/new")
    }

    useEffect(() => {
        const get = async () => {
            var docs;
            if (admin) {
                docs = await getLeaveApplicationsByOffice(currentUser.data.oid);
            } else if (superadmin) {
                docs = await getLeaveApplications();
            } else if (goadmin) {
                docs = await getApprovedLeaveApplications();
            } else {
                docs = await getUserLeaveApplications(currentUser.uid);
            }
            setTravelOrders(docs);
        }
        get();
    }, []);

    useEffect(() => {
        const get = async () => {
            var docs = await getLeaveCredits(currentUser.uid);
            setLeaveCredits(docs);
        }
        get();
    }, []);

    var total = 0;
    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                <h2 className='is-size-2 has-text-weight-bold  mt-6'>Leave Applications
                            {!admin && !goadmin && !superadmin ? <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onClickHandler}>Apply for Leave</Button> : ""}</h2>
                            
                    <div className='columns mt-5'>
                        <div className={`column ${currentUser.data.type === "user" ? "is-8" : "is-12"}`}>
                            <div className="block pb-6">
                                {travelOrders.map((leave) => {
                                        return <LeaveItem key={leave.id} leave={leave}/>
                                    })
                                }

                                {travelOrders.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold'>Empty</p><p>No leave applications</p></div> : ""}
                            </div>
                        </div>

                    {currentUser.data.type === "user" ? 
                        <div className='column is-4'>
                        <div className='card px-6 py-5 '>
                            <h2 className='is-size-5 has-text-weight-bold'>Leave Credits History</h2>
                            <hr className='my-5'/>
                            <div className='block'>
                                {leaveCredits.map((leave) => {
                                    total += leave.value;
                                    return (
                                        <div key={leave.id} className='py-4'>
                                        <span>{format(leave.created.toDate(), "MMMM dd, yyyy")}</span>
                                            <span className='has-text-weight-bold is-pulled-right'>{leave.value}</span>
                                        </div>
                                    )
                                })}

                                    <div className='py-4'>
                                        <span>Remaining Credits:</span>
                                            <span className='has-text-weight-bold is-pulled-right'>{total}</span>
                                        </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                    </div>

                    </div>
                </div>
            </div>
    )
}

export default LeaveApplications;