import { useState, useContext } from 'react'
import { createAuthUserWithEmailAndPassword, createUserDocument } from '../../../utils/firebase'
import FormInput from '../../../components/form-input'
import Button from '../../../components/button'
import {useNavigate} from 'react-router-dom';
import { OfficesContext } from '../../../context/offices-context';
import FormSelect from '../../../components/form-select';

const defaultFormFields = {
    surname: '',
    firstName: '',
    middleName: '',
    nameExtension: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    type: 'user',
    approved: false,
    deactivated: false,
    office: 'Select',
    oid: '',
    employment: 'Select'
}

const SignUpForm = () => {
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { firstName, middleName, surname, nameExtension, emailAddress, password, confirmPassword, employment, office } = formFields;
    const { offices } = useContext(OfficesContext);
    const result = offices.map(a => a.name);

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { id, value } = event.target;

        if (id === "office") {
            const pos = result.indexOf(value);
            setFormFields({...formFields, [id]: value, oid: offices[pos].id});
            return
        }

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (office === "Select") {
            alert("Select office");
            return;
        }

        if (employment === "Select") {
            alert("Select office");
            return;
        }

        if (password != confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            const { user } = await createAuthUserWithEmailAndPassword(emailAddress, password);

            delete formFields["confirmPassword"];
            delete formFields["password"];
            delete formFields["office"];

            const fullName = firstName + " " + middleName + " " + surname;
            const search = fullName.toLowerCase().split(" ");

            formFields["search"] = search;
            await createUserDocument(user, formFields);
            resetFormFields();
            navigate('/');
        } catch (error) {
            if (error.code == "auth/email-already-in-use") {
                alert("Email address already in use");
                return;
            }

            console.log("signup error:", error.message);
        }
    }

    return (
        <div className='column mt-6'>
            <div className='columns mt-6'>
            <div className='column is-6 is-offset-3'>
            <h2 className='is-size-2 has-text-weight-bold'>Sign up</h2>
            <p className='block'>Fill in the following fields</p>
                <form onSubmit={handleSubmit}>
                <div className='columns'>
                <FormInput type="text" required id="firstName" value={firstName} onChange={handleChange} label="First Name" additionalClasses="column is-6"/>
                            <FormInput type="text" required id="middleName" value={middleName} onChange={handleChange} label="Middle Name" additionalClasses="column is-6"/>
                            
                </div>
                <div className='columns'>
                <FormInput type="text" required id="surname" value={surname} onChange={handleChange} label="Surname" additionalClasses="column is-6"/>
                            <FormInput type="text" id="nameExtension" value={nameExtension} onChange={handleChange} label="Name Extension" additionalClasses="column is-6"/>
                            
                </div>
                <div className='columns'>
                <FormInput type="email" required id="emailAddress" value={emailAddress} onChange={handleChange} label="Email Address" additionalClasses="column is-12"/>
                            </div>
                            <div className='columns'>
                            <FormSelect options={result} type="text" required id="office" value={office} onChange={handleChange} label="Office" additionalClasses="column is-6"/>
                            <FormSelect options={["Elected", "Regular", "Co-Term", "Casual", "COS", "Job Order"]} type="text" required id="employment" value={employment} onChange={handleChange} label="Employment" additionalClasses="column is-6"/>
                            </div>
                <div className='columns'>
                <FormInput type="password" required id="password" value={password} onChange={handleChange} label="Password" additionalClasses="column is-6"/>
                <FormInput type="password" required id="confirmPassword" value={confirmPassword} onChange={handleChange} label="Confirm Password" additionalClasses="column is-6"/>
                </div>
                    <Button type="submit" additionalClasses="is-success block">Sign up</Button>
                    <p className='is-size-6 block'>Already have an account? <a className='has-text-weight-semibold' onClick={() => {navigate('/');}}>Sign in</a></p>
                </form>
            </div>
        </div>
    </div>
    )
}

export default SignUpForm;