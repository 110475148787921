import Button from "./button";

const SearchItem = ({user, additionalClasses, onClickHandler, buttonText, office, index, ...otherProps}) => {
    const { surname, firstName, middleName, emailAddress, id } = user;
    return (
        <span {...otherProps} className={additionalClasses} onClick={buttonText !== "Remove" ? () => {index > -1 ? onClickHandler(index, user) : onClickHandler(user)} : null}>
            {middleName ? surname + ", " + firstName + " " + middleName.substring(0, 1) + "." : surname + ", " + firstName}
            {office ? <small><br/><strong>{office.short}</strong></small> : ""}

            {buttonText === "Remove" ? <button className="ml-3 delete" onClick={() => {index > -1 ? onClickHandler(index, user) : onClickHandler(user)}}></button> : ""}
        </span>
    )
}

export default SearchItem;