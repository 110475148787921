import { useState } from 'react';
import FormInput from '../../../components/form-input';
import Button from '../../../components/button';
import {useNavigate} from 'react-router-dom';
import { resetPassword } from '../../../utils/firebase';

const ForgotPassword = () => {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await resetPassword(email);
        if (response === "success") {
            setEmail("");
            alert("Password Reset Link has been sent.");
        } else {
            alert(response);
        }
    }

    return (
        <div className='column mt-6'>
            <div className='columns mt-6'>
                <div className='column is-4 is-offset-4'>
                    <h2 className='is-size-2 has-text-weight-bold'>Forgot Password</h2>
                <p className='block'>We will send you a password reset link, please type in your email address.</p>
                    <form onSubmit={handleSubmit}>
                        <FormInput type="email" required id="email" value={email} onChange={(e) => {setEmail(e.target.value)}} label="Email Address" additionalClasses="block"/>
                        <Button type="submit" additionalClasses="is-fullwidth is-success block">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;