import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import ApplicantItem from '../components/applicant-item';
import { approveUserDocument, disapproveUserDocument, getUserDocuments } from '../utils/firebase';
import { OfficesContext } from '../context/offices-context';

const Applicants = () => {
    const { currentUser } = useContext(UserContext);
    const [users, setUsers = () => []] = useState([]);
    const { offices } = useContext(OfficesContext);

    useEffect(() => {
        const getUsers = async () => {
            const docs = await getUserDocuments(false);
            setUsers(docs);
        }
        getUsers();
    }, []);

    const onClickHandler = async(approve, userToRemove) => {
        var response;
        if (approve) {
            response = await approveUserDocument(userToRemove.id);
        } else {
            response = await disapproveUserDocument(userToRemove.id);
        }
        if (response === "success") {
            const use = users.filter(user => user.id !== userToRemove.id);
            setUsers(use);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Applicants</h2>
                        <div className="block pb-6">
                            {users.map((user) => {
                                    const pos = offices.map(a => a.id).indexOf(user.oid);
                                    return <ApplicantItem key={user.id} office={pos > -1 ? offices[pos] : null} user={user} onClickHandler={onClickHandler}/>
                                })
                            }
                            
                            {users.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold'>Empty</p><p>No applicants</p></div> : ""}
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Applicants;