import { useContext, useState } from 'react';
import { UserContext } from "../../context/user-context";
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { createLog, updateUserDocument } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';

const CivilServiceEligibility = () => {
    const { currentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState({eligibility: currentUser["data"]["eligibility"] ? currentUser["data"]["eligibility"] : []});
    const navigate = useNavigate();
    const { eligibility } = formFields;

    const handleChange = (index, event) => {
        const { id, value } = event.target;
        eligibility[index][id] = value;

        setFormFields({...formFields});
    }

    const handleOnAdd = () => {
        eligibility.push({
            "type" : "",
            "rating" : "",
            "date": "",
            "place": "",
            "number": "",
            "validity": "",
        });

        setFormFields({...formFields});
    }

    const handleOnRemove = (index) => {
        eligibility.splice(index, 1);

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await updateUserDocument(currentUser["uid"], formFields);
        if (response === "success") {
            const log = {"action": "User updated PDS eligibility", "by" : currentUser.uid}
            await createLog(log);

            Object.assign(currentUser["data"], formFields);
        }
        alert(response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Civil Service Eligibility</h2>
                    <form onSubmit={handleSubmit}>
                        {eligibility.map((csc, index) => {
                            return (
                                <div className='block' key={index}>
                                    <div className='columns is-vcentered'>
                                        <FormInput type="text" required id="type" value={csc["type"]} onChange={(e) => handleChange(index, e)} label="Career Service/RA 1080 (Board/BAR) Under Special Laws/CES/CSEE/Barangay Eligibility/Driver's License" additionalClasses="column is-10"/>
                                        <div className='column is-1' style={{marginTop: "22px"}}><button type='button' onClick={() => handleOnRemove(index)} className="delete is-large"></button></div>
                                    </div>
                                    
                                    <div className='columns'>
                                        <FormInput type="number" id="rating" value={csc["rating"]} onChange={(e) => handleChange(index, e)} label="Rating" additionalClasses="column is-5"/>
                                        <FormInput type="date" id="date" value={csc["date"]} onChange={(e) => handleChange(index, e)} label="Date of Examination/Conferment" additionalClasses="column is-5"/>
                                    </div>
                                    <div className='columns'>
                                        <FormInput type="text" required id="place" value={csc["place"]} onChange={(e) => handleChange(index, e)} label="Place of Examination/Conferment" additionalClasses="column is-10"/>
                                    </div>
                                    
                                    <div className='columns'>
                                        <FormInput type="number" required id="number" value={csc["number"]} onChange={(e) => handleChange(index, e)} label="License No." additionalClasses="column is-5"/>
                                        <FormInput type="date" required id="validity" value={csc["validity"]} onChange={(e) => handleChange(index, e)} label="Date of Validity" additionalClasses="column is-5"/>
                                    </div>
                                </div>
                            )
                        })}
                        <Button additionalClasses="block mr-4" type="button" onClick={handleOnAdd}>Add Eligibility</Button>
                        <Button additionalClasses="block is-success" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CivilServiceEligibility;