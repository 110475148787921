import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import EmployeeItem from '../components/employee-item';
import { getUsersByEmployment } from '../utils/firebase';
import { OfficesContext } from '../context/offices-context';
import {useNavigate} from 'react-router-dom';
import Button from '../components/button';
import { format } from "date-fns";
import ighrsReport from '../assets/ighrs-report.xlsx'
import ExcelJS from 'exceljs';
import FormSelect from '../components/form-select';

const IGHRSReport = () => {
    const { currentUser } = useContext(UserContext);
    const [users, setUsers = () => []] = useState([]);
    const { offices } = useContext(OfficesContext);
    const navigate = useNavigate();
    const [employment, setEmployment] = useState("Select");

    const get = async () => {
        if (employment === "Select") {
            alert("Select employment");
            return;
        }

        var docs = await getUsersByEmployment(employment);
        setUsers([...users, ...docs]);
    }

    const onPrintHandler = async() => {
        let blob = await fetch(ighrsReport).then(r => r.blob());

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(blob);

        const sheet = workbook.getWorksheet("Sheet1");

        users.forEach((user, index) => {
            sheet.insertRow(10 + index, [index + 1, user.surname, user.firstName, user.nameExtension, user.middleName, user.middleName.substring(0, 1), user.personal ? format(new Date(user.personal.birthDate), "MM/dd/yyyy") : "-", user.personal ? user.personal.sex : "-", "-", "-", user.employment, "-", "-", "-"], 'i');
        })

        sheet.spliceRows(9, 1);

        const buffer = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(
          new Blob([buffer]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'ighrs-report.xlsx',
        );
    
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                <h2 className='is-size-2 has-text-weight-bold  mt-6'>IGHRS Report
                <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onPrintHandler}>Download</Button></h2>

                <div className='columns is-vcentered my-6'>
                    <FormSelect options={["All", "Elected", "Regular", "Co-Term", "Casual", "COS", "Job Order"]} type="text" required id="employment" value={employment} onChange={(e) => {setEmployment(e.target.value)}} additionalClasses="column is-6"/>
                    
                    <div className='column is-3'>
                        <Button additionalClasses="block is-fullwidth" type="submit" onClick={() => {get()}}>Add</Button>
                    </div>
                    
                    <div className='column is-3'>
                        <Button additionalClasses="block is-fullwidth is-danger" type="submit" onClick={() => {setUsers([])}}>Clear</Button>
                    </div>
                </div>
                           
                    {users.map((user) => {
                        return <EmployeeItem key={user.id} user={user}/>
                        })
                    }
                    </div>
                </div>
            </div>
    )
}

export default IGHRSReport;