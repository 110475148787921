import { createContext, useState, useEffect } from "react";
import { getOffices } from "../utils/firebase";

export const OfficesContext = createContext({
    offices: [],
    setOffices: () => null,
    addOfficeToContext: () => {},
    updateOfficeInContext: () => {},
});

const addOffice = (offices, office) => {
    return [...offices, office];
}

const updateOffice = (offices, officeToUpdate) => {
    return offices.map((office) => office.id === officeToUpdate.id ? {...officeToUpdate} : office);
}

export const OfficesProvider = ({ children }) => {
    const [offices, setOffices] = useState([]);

    const addOfficeToContext = (office) => {
        setOffices(addOffice(offices, office));
    }

    const updateOfficeInContext = (office) => {
        setOffices(updateOffice(offices, office));
    }

    const value = {offices, setOffices, addOfficeToContext, updateOfficeInContext};

    useEffect(() => {
        const getResponse = async () => {
            setOffices(await getOffices());
        }
        getResponse();
    }, []);

    return <OfficesContext.Provider value={value}>{children}</OfficesContext.Provider>
} 