import { useContext, useState } from 'react';
import { UserContext } from "../context/user-context";
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import { createOffice } from '../utils/firebase';

const defaultFormFields = {
    name: '',
    short: '',
    chief: '',
    designation: '',
}

const NewOffice = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { addOfficeToContext } = useContext(OfficesContext);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { name, short, chief, designation } = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await createOffice(formFields);
        if (response === "error") {
            alert(response);
            return;
        }

        formFields["id"] = response;
        addOfficeToContext(formFields);
        resetFormFields();
        navigate('/offices/' + response);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Office</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                    <div className='columns is-multiline'>
                                        <FormInput type="text" required id="name" value={name} onChange={handleChange} label="Office Name" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="short" value={short} onChange={handleChange} label="Office Acronym" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="chief" value={chief} onChange={handleChange} label="Name of Chief" additionalClasses="column is-6"/>
                                        <FormInput type="text" required id="designation" value={designation} onChange={handleChange} label="Designation of Chief" additionalClasses="column is-6"/>
                                    </div>
                                    <Button additionalClasses="block is-success" type="submit">Submit</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewOffice;