import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { useLocation } from 'react-router-dom'
import { OfficesContext } from '../context/offices-context';
import AdminDashboardCard from '../components/admin-dashboard-card';
import EmployeeItem from '../components/employee-item';
import { getUsersByOffice } from '../utils/firebase';
import {useNavigate} from 'react-router-dom';
import Button from '../components/button';

const employment = [
    {
        "id": 1,
        "text": "All"
    },
    {
        "id": 2,
        "text": "Elected"
    },
    {
        "id": 3,
        "text": "Regular"
    },
    {
        "id": 4,
        "text": "Co-Term"
    },
    {
        "id": 5,
        "text": "Casual"
    },
    {
        "id": 6,
        "text": "COS"
    },
    {
        "id": 7,
        "text": "Job Order"
    }
]

const Office = () => {
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const location = useLocation();
    const [users, setUsers = () => []] = useState([]);
    const navigate = useNavigate();
    
    const id = location.pathname.replace("/offices/", "");
    const pos = offices.map(a => a.id).indexOf(id);
    const { name, short } = offices[pos];

    const onClickHandler = () => {
        navigate("/offices/" + id + "/update")
    }

    useEffect(() => {
        const getUsers = async () => {
            const docs = await getUsersByOffice(id);
            setUsers(docs);
        }
        getUsers();
    }, []);

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold mt-6'>{short}
                        <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onClickHandler}>Update</Button></h2>
                    <p className='mb-6'>{name}</p>
                        <div className="block pb-6">
                            <div className='columns is-multiline mb-6'>
                                {employment.map((emp) => {
                                    return <AdminDashboardCard office={id} key={emp.id} employment={emp.text} additionalClasses="column is-3"/>
                                })}
                                
                            </div>

                            {users.map((user) => {
                                        return <EmployeeItem key={user.id} user={user}/>
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Office;