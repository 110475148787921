import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { getServiceRecords, getUserDocument } from '../utils/firebase';
import { useLocation, useNavigate } from 'react-router-dom'
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import { Fragment } from 'react';
import serviceRecord from '../assets/service-record.xlsx'
import ExcelJS from 'exceljs';
import { format } from "date-fns";

const defaultForm = {
    surname: "",
    firstName: "",
    middleName: "",
    nameExtension: "",
    files: null,
    oid: ""
}
const ServiceRecord = () => {
    const { currentUser } = useContext(UserContext);
    const [user, setUser] = useState(defaultForm);
    const location = useLocation();
    const { offices } = useContext(OfficesContext);
    const navigate = useNavigate();
    const [serviceRecords, setServiceRecords = () => []] = useState([]);

    const id = location.pathname.replace("/service-record/", "");

    useEffect(() => {
        const getUsers = async () => {
            const doc = await getUserDocument(id);
            setUser(doc);
        }
        getUsers();
    }, []);

    useEffect(() => {
        const get = async () => {
            const docs = await getServiceRecords(id);
            setServiceRecords(docs);
        }
        get();
    }, []);

    const { surname, firstName, middleName, nameExtension, files, oid, personal } = user;
    const pos = offices.map(a => a.id).indexOf(oid);

    const onPrintHandler = async() => {
        let blob = await fetch(serviceRecord).then(r => r.blob());

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(blob);

        const sheet = workbook.getWorksheet("Sheet1");
        sheet.getCell('B4').value = surname;
        sheet.getCell('D4').value = firstName;
        sheet.getCell('G4').value = middleName;

        if (personal) {
            sheet.getCell('B7').value = personal.birthDate;
            sheet.getCell('D7').value = personal.birthPlace;
        }

        serviceRecords.forEach((sr) => {
            sheet.insertRow(19, [format(new Date(sr.from), "MM/dd/yyyy"), format(new Date(sr.to), "MM/dd/yyyy"), sr.designation, sr.status, sr.salary, "/m", sr.station, sr.branch, sr.leave, sr.date, sr.cause], 'i');
        })

        sheet.spliceRows(18, 1);

        const buffer = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(
          new Blob([buffer]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          firstName.toLowerCase() + '_' + surname.toLowerCase() + '_service_record.xlsx',
        );
    
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-10 is-offset-1'>
                        <div className="block pb-6 mt-6">
                        {currentUser.data.type === "superadmin" ? 
                        <Fragment>
                            <div className='column is-12 has-text-centered'>
                                <figure className="image is-128x128 is-inline-block">
                                <img className="is-rounded" src={files ? files.photoFileUrl ? files.photoFileUrl : "https://bulma.io/images/placeholders/128x128.png" : "https://bulma.io/images/placeholders/128x128.png"}/>
                                </figure>
                            
                            <div className='column is-12 has-text-centered'>
                                <h2 className='is-size-2 has-text-weight-bold'>{middleName ? firstName + " " + middleName.substring(0, 1) + ". " + surname: firstName + " " + surname}</h2>
                                {pos > -1 ? <p className='is-size-5'>{offices[pos].name}</p> : ""}
                            </div>
                            </div>

                            <div className="notification is-success py-5 mt-5 is-clickable" onClick={() => {navigate("/pds/" + user.id)}}>
                            <strong>Personal Data Sheet <i className="fas fa-arrow-right is-pulled-right mt-1"></i></strong> 
                            </div>
                        </Fragment>
                    : ""}

                        <h2 className='is-size-2 has-text-weight-bold my-6'>Service Record
                            <div className='is-pulled-right my-3'>
                            <Button additionalClasses="block" type="submit" onClick={onPrintHandler}>Download</Button>
                                {currentUser.data.type === "superadmin" ?
                                <Button additionalClasses="block is-success ml-3" type="submit" onClick={() => {navigate("/service-record/" + user.id + "/new")}}>Create New</Button>
                                : ""}
                            </div>
                        </h2>
                        
                        <div className="block pb-6">
                            <table className="table is-fullwidth is-bordered" style={{backgroundColor: "transparent"}}>
                                <thead>
                                    <tr>
                                        <th className='has-text-centered' colSpan="2">SERVICE RECORD</th>
                                        <th className='has-text-centered is-vcentered' rowSpan="3">Designation</th>
                                        <th className='has-text-centered is-vcentered' rowSpan="2">Status</th>
                                        <th className='has-text-centered is-vcentered' rowSpan="2">Salary</th>
                                        <th className='has-text-centered is-vcentered' rowSpan="3">Station/Place of Assignment</th>
                                        <th className='has-text-centered is-vcentered' rowSpan="3">Branch</th>
                                        <th className='has-text-centered is-vcentered' rowSpan="3">Leave/Absent w/o Pay</th>
                                        <th className='has-text-centered' colSpan="2">Separation</th>
                                        <th className='has-text-centered' rowSpan="3"></th>
                                    </tr>
                                    <tr>
                                        <th className='has-text-centered' colSpan="2">(Inclusive Dates)</th>
                                        <th className='has-text-centered'>Date</th>
                                        <th className='has-text-centered'>Cause</th>
                                    </tr>
                                    <tr>
                                        <th className='has-text-centered'>From</th>
                                        <th className='has-text-centered'>To</th>
                                        <th className='has-text-centered'>(1)</th>
                                        <th className='has-text-centered'>(2)</th>
                                        <th className='has-text-centered' colSpan="2">(3)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceRecords.map((serviceRecord, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{format(new Date(serviceRecord.from), "MM/dd/yyyy")}</td>
                                                <td>{format(new Date(serviceRecord.to), "MM/dd/yyyy")}</td>
                                                <td>{serviceRecord.designation}</td>
                                                <td>{serviceRecord.status}</td>
                                                <td>{serviceRecord.salary}/m</td>
                                                <td>{serviceRecord.station}</td>
                                                <td>{serviceRecord.branch}</td>
                                                <td>{serviceRecord.leave}</td>
                                                {serviceRecord.separation === "Still in the service" ?
                                                <td colSpan="2">{serviceRecord.separation}</td> : <Fragment><td>{serviceRecord.date}</td><td>{serviceRecord.cause}</td></Fragment>}
                                                <td><Button additionalClasses="block" type="button" onClick={() => {navigate("/service-record/" + user.id + "/update/" + serviceRecord.id)}}><i className="fas fa-pen"></i></Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ServiceRecord;