import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'javascript-time-ago'
import {useNavigate} from 'react-router-dom';
import en from 'javascript-time-ago/locale/en.json'
import Button from "./button";
TimeAgo.addLocale(en)

const LeaveItem = ({leave}) => {
    const navigate = useNavigate();
    const { id, created, status, type } = leave;

    const onClickHandler = () => {
        navigate('/leave-applications/' + id);
      }

    return (
        <div className="column is-12">
            <article className="media">
                <div className="media-content">
                    <div className="content">
                    <p>
                        <strong>{type}</strong>
                            <span className={`${status === "Approved" ? "is-success" : status === "Disapproved" ? "is-danger" : "is-warning"} tag ml-2`}>{status}</span>
                        <br/>
                        <small> <ReactTimeAgo date={created.toDate()} locale="en-US"/></small>
                    </p>
                    </div>
                </div>
                <div className="media-right">
                    <Button type="button" onClick={onClickHandler}>View</Button>
                </div>
            </article>
        </div>
    )
}

export default LeaveItem;