import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import Button from '../components/button';
import AdminItem from '../components/admin-item';
import { getAdminDocuments } from '../utils/firebase';
import {useNavigate} from 'react-router-dom';

const Accounts = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [users, setUsers = () => []] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const docs = await getAdminDocuments();
            setUsers(docs);
        }
        getUsers();
    }, []);

    const onClickHandler = () => {
        navigate("/accounts/new")
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Accounts
                        <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onClickHandler}>Create New</Button></h2>
                        <div className='block pb-6'>
                        {users.map((user) => {
                                        return <AdminItem key={user.id} user={user}/>
                                    })
                                }
                            {users.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold'>Empty</p><p>No applicants</p></div> : ""}
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default Accounts;