import { useContext, useState } from 'react';
import { UserContext } from "../context/user-context";
import Button from '../components/button';
import {useNavigate, useParams} from 'react-router-dom';
import FormInput from '../components/form-input';
import FormTextArea from '../components/form-textarea';
import { createUpdate, deleteUpdate, getUpdate, updateUpdate, uploadUpdate } from '../utils/firebase';
import { useEffect } from 'react';

const defaultFormFields = {
    title: "",
    article: "",
    imageFileUrl: "",
    imageFileName: "",
    file: null
}

const UpdateUpdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { title, article, file, imageFileName } = formFields;

    useEffect(() => {
        const get = async () => {
            const doc = await getUpdate(id);
            setFormFields(doc);
        }
        get();
    }, []);

    const handleChange = async(event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleUpload = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }
        setFormFields({...formFields, "file": files[0]});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        var data = {title, article};
        if (file) {
            const up = await uploadUpdate(file);
            if (up.includes("error")) {
                alert(up);
                return;
            }
            data = {title, article, "imageFileUrl": up, "imageFileName": file.name};
        }

        const res = await updateUpdate(id, data);
        if (res === "success") {
            navigate("/news-updates/" + id);
            return;
        }

        alert(res);
    }

    const onDeleteHandler = async() => {
        const con = window.confirm("Are you sure you want to delete article?")
        if (con) {
            const response = await deleteUpdate(id);
            if (response === "success") {
                navigate("/news-updates");
                return;
            }
            alert(response);
        }
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Update Article
                    <Button additionalClasses="block is-danger is-pulled-right my-3" type="button" onClick={onDeleteHandler}>Delete</Button></h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                <div className='columns'>
                                    <div className='column is-12'>
                                        <label>File Upload</label>
                                        <div className="file has-name is-fullwidth">
                                            <label className="file-label">
                                                <input className="file-input" type="file" onChange={handleUpload} id='image'
                                                accept= "image/*"/>
                                                <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    Choose a file…
                                                </span>
                                                </span>
                                                <span className="file-name">
                                                    {file ? file.name : imageFileName}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                    <FormInput type="text" required id="title" value={title} onChange={handleChange} label="Title" additionalClasses="column is-12"/>
                                    <FormTextArea type="text" required id="article" value={article} onChange={handleChange} label="Body" additionalClasses="column is-12"/>
                                    <Button additionalClasses="block is-success" type="submit">Save</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default UpdateUpdate;