import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { adminSearchUsers, getUserDocuments, searchUsers } from '../utils/firebase';
import AdminDashboardCard from '../components/admin-dashboard-card';
import { OfficesContext } from '../context/offices-context';
import SearchItem from '../components/search-item';
import { useNavigate } from 'react-router-dom';

const employment = [
    {
        "id": 1,
        "text": "All"
    },
    {
        "id": 2,
        "text": "Elected"
    },
    {
        "id": 3,
        "text": "Regular"
    },
    {
        "id": 4,
        "text": "Co-Term"
    },
    {
        "id": 5,
        "text": "Casual"
    },
    {
        "id": 6,
        "text": "COS"
    },
    {
        "id": 7,
        "text": "Job Order"
    }
]

const AdminDashboard = () => {
    const { currentUser } = useContext(UserContext);
    const [users, setUsers = () => []] = useState([]);
    const { offices } = useContext(OfficesContext);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            const s = search.toLowerCase().trim().split(" ");
            const docs = await adminSearchUsers(s);
            setUsers(docs);
        }
        getUsers();
    }, [search]);

    const onAddHandler = async(user) => {
        navigate("/pds/" + user.id)
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <div className='columns is-vcentered'>
                        <h2 className='is-size-2 has-text-weight-bold my-6 column is-6 has-text-white'>Home</h2>

                        <div className='custom-dropdown column is-6'>
                            <div className='custom-input'>
                                    <input placeholder='Search' autoComplete="off" type="text" id="search" value={search} onChange={(e) => {setSearch(e.target.value)}} />
                            </div>
                            {search ? users.length > 0 ?
                                <div className="custom-dropdown-menu">
                                    <div className='custom-dropdown-content'>
                                    {users.map((user, index) => {
                                        const pos = offices.map(a => a.id).indexOf(user.oid);
                                            return <SearchItem tabIndex={index} key={user.id} office={pos > -1 ? offices[pos] : null} user={user} buttonText="Add" onClickHandler={onAddHandler} additionalClasses="column is-12"/>
                                            })
                                        }
                                        </div>
                                    </div>
                                : 
                                <div className="custom-dropdown-menu">
                                    <div className='custom-dropdown-content has-text-centered py-5'>
                                        No results found
                                    </div>
                                </div>
                            : ""}
                        </div>
                    </div>
                    <div className='block pb-6'>
                        <div className='columns is-multiline'>
                            {employment.map((emp) => {
                                return  <AdminDashboardCard key={emp.id} employment={emp.text} additionalClasses="column is-3"/>
                            })}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default AdminDashboard;