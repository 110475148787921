import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import OfficeItem from '../components/office-item';
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';

const Offices = () => {
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate("/offices/new")
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Offices 
                        <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onClickHandler}>Create New</Button></h2>
                        <div className="block pb-6">
                            <div className='columns is-multiline'>
                                {offices.map((office) => {
                                        return <OfficeItem key={office.id} office={office} additionalClasses="column is-4"/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Offices;