import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'javascript-time-ago'
import {useNavigate} from 'react-router-dom';
import en from 'javascript-time-ago/locale/en.json'
import Button from "./button";
TimeAgo.addLocale(en)

const TravelItem = ({travelOrder}) => {
    const navigate = useNavigate();

    const { id, created, destination, participants, status } = travelOrder;
    const size = participants.length;
    const remaining = participants.length - 1;

    const onClickHandler = () => {
        navigate('/travel-orders/' + id);
      }

    return (
        <article className="media">
            <div className="media-content">
                <div className="content">
                <p>
                    <strong>{size === 0 ? "" : size === 1 ? participants[0].surname : size === 2 ? participants[0].surname + " & " + participants[1].surname
                        : participants[0].surname + " & "  + remaining + " Others"}</strong>
                        <span className={`${status === "For Approval" ? "is-warning" : status === "Approved" ? "is-success" : status === "Disapproved" ? "is-danger" : ""} tag ml-2`}>{status}</span>
                    <br/>
                    {destination}
                    <br/>
                    <small> <ReactTimeAgo date={created.toDate()} locale="en-US"/></small>
                </p>
                </div>
            </div>
            <div className="media-right">
                <Button type="button" onClick={onClickHandler}>View</Button>
            </div>
        </article>
    )
}

export default TravelItem;