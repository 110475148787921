import { useContext, useState } from 'react';
import { UserContext } from "../context/user-context";
import { OfficesContext } from '../context/offices-context';
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import { createAuthUserWithEmailAndPassword, createUserDocument, signOutUser } from '../utils/firebase';
import FormSelect from '../components/form-select';

const defaultFormFields = {
    name: '',
    type: 'admin',
    emailAddress: '',
    office: 'Select',
}

const NewAccount = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(OfficesContext);
    const result = offices.map(a => a.name);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { name, short, emailAddress, office } = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { id, value } = event.target;

        if (id === "office") {
            const pos = result.indexOf(value);
            const office = offices[pos];
            if (office.short === "GO") {
                formFields["type"] = "go-admin";
            } else {
                formFields["type"] = "admin";
            }
            setFormFields({...formFields, [id]: value, oid: office.id});
            return
        }

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (office === "Select") {
            alert("Select office");
            return;
        }
        
        try {
            const { user } = await createAuthUserWithEmailAndPassword(emailAddress, "hris123");

            delete formFields["office"];
            await createUserDocument(user, formFields);
            resetFormFields();
            // TODO FIX THIS
            // navigate('/accounts');

            signOutUser();
            navigate('/');
        } catch (error) {
            if (error.code == "auth/email-already-in-use") {
                alert("Email address already in use");
                return;
            }

            console.log("signup error:", error.message);
        }
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Account</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                    
                    <div className='columns'>
                    <FormInput type="text" required id="name" value={name} onChange={handleChange} label="Name" additionalClasses="column is-6"/>
                    <FormInput type="email" required id="emailAddress" value={emailAddress} onChange={handleChange} label="Email Address" additionalClasses="column is-6"/>
                            </div>
                                <div className='columns'>
                                <FormSelect options={result} type="text" required id="office" value={office} onChange={handleChange} label="Office" additionalClasses="column is-6"/>
                                </div>
                                    <Button additionalClasses="block is-success" type="submit">Submit</Button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewAccount;