import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import { useLocation } from 'react-router-dom'
import { OfficesContext } from '../context/offices-context';
import { downloadFile, getContract, getTravelOrder, updateTravelOrderDocument } from '../utils/firebase';
import seal from '../assets/seal.png'
import pagasa from '../assets/pagasa.png'
import './travel-order.scss'
import html2canvas from 'html2canvas';
import { format } from "date-fns";
import Button from '../components/button';

const defaultFormField = {
    list: [],
    fileName: "",
    created: new Date(),
    url: ""
}

const Contract = () => {
    const { currentUser } = useContext(UserContext);
    const location = useLocation();
    const [contract, setContract = () => []] = useState(defaultFormField);
    const {list, fileName, created, url} = contract;

    const id = location.pathname.replace("/contracts/", "");
    useEffect(() => {
        const getTO = async () => {
            const doc = await getContract(id);
            setContract(doc);
        }
        getTO();
    }, []);

    const downloadHandler = () => {
        downloadFile(fileName, url);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='mt-6'><span className='is-size-2 has-text-weight-bold '>Contract</span>
                    <div className='block is-pulled-right my-3'>
                        <Button additionalClasses="mx-3" type="button" onClick={downloadHandler}>Download</Button>
                        <Button additionalClasses="is-danger" type="button">Delete</Button>
                    </div>
                    </h2>

                    <div className='block pb-6 mt-6'>
                        <table className="table is-fullwidth is-borderless" style={{backgroundColor: "transparent"}}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {list.map((item, index) => {
                                    const {designation, from, to, funding, office, rate, user} = item;
                                    const pos = index + 1;
                                    return (
                                        <tr key={index}>
                                            <td>{pos}</td>
                                            <td>{user.surname + ", " + user.firstName} {user.middleName ? user.middleName.substring(0, 1) + "." : ""}</td>
                                            <td>{designation}</td>
                                            <td>{from}</td>
                                            <td>{to}</td>
                                            <td>{rate}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Contract;