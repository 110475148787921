import { useContext, useState } from 'react';
import { UserContext } from "../context/user-context";
import Button from '../components/button';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import FormTextArea from '../components/form-textarea';
import { createUpdate, uploadUpdate } from '../utils/firebase';

const defaultFormFields = {
    title: "",
    article: "",
    file: null
}

const NewUpdate = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { title, article, file } = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = async(event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleUpload = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }
        setFormFields({...formFields, "file": files[0]});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (file === null) {
            alert("Upload a photo");
            return;
        }
        

        const up = await uploadUpdate(file);
        if (up.includes("error")) {
            alert(up);
            return;
        }

        const res = await createUpdate({title, article, "imageFileUrl": up, "imageFileName": file.name});
        if (res.includes("error")) {
            alert(res);
            return;
        }

        navigate("/news-updates/" + res);
    }

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>New Article</h2>
                        <div className="block pb-6">
                                <form onSubmit={handleSubmit}>
                                <div className="columns is-multiline">
                                    <div className='column is-12'>
                                        <label>File Upload</label>
                                        <div className="file has-name is-fullwidth">
                                            <label className="file-label">
                                                <input required className="file-input" type="file" onChange={handleUpload} id='image'
                                                accept= "image/*"/>
                                                <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    Choose a file…
                                                </span>
                                                </span>
                                                <span className="file-name">
                                                    {file ? file.name : ""}
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <FormInput type="text" required id="title" value={title} onChange={handleChange} label="Title" additionalClasses="column is-12"/>
                                    <FormTextArea type="text" required id="article" value={article} onChange={handleChange} label="Body" additionalClasses="column is-12"/>
                                    
                                    <Button additionalClasses="block is-success mt-6" type="submit">Publish</Button>
                                </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default NewUpdate;