import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/user-context";
import TravelItem from '../components/travel-item';
import { getTravelOrdersByOffice, getUserTravelOrders, getTravelOrders, getApprovedTravelOrders } from '../utils/firebase';
import { OfficesContext } from '../context/offices-context';
import {useNavigate} from 'react-router-dom';
import Button from '../components/button';

const TravelOrders = () => {
    const { currentUser } = useContext(UserContext);
    const [travelOrders, setTravelOrders = () => []] = useState([]);
    const { offices } = useContext(OfficesContext);
    const navigate = useNavigate();

    const admin = currentUser.data.type === "admin";
    const goadmin = currentUser.data.type === "go-admin";
    const superadmin = currentUser.data.type === "superadmin";
    const onClickHandler = () => {
        navigate("/travel-orders/new")
    }

    useEffect(() => {
        const get = async () => {
            var docs;
            if (admin) {
                docs = await getTravelOrdersByOffice(currentUser.data.oid);
            } else if (superadmin) {
                docs = await getApprovedTravelOrders();
            } else if (goadmin) {
                docs = await getTravelOrders();
            } else {
                docs = await getUserTravelOrders(currentUser.uid);
            }
            setTravelOrders(docs);
        }
        get();
    }, []);

    return (
        <div className='column'>
            <div className='columns main'>
                <div className='column is-8 is-offset-2'>
                    <h2 className='is-size-2 has-text-weight-bold my-6'>Travel Orders
                        {admin || goadmin ? <Button additionalClasses="block is-success is-pulled-right my-3" type="submit" onClick={onClickHandler}>Create Travel Order</Button> : ""}</h2>
                        <div className="block pb-6">
                            {travelOrders.map((travelOrder) => {
                                    return <TravelItem key={travelOrder.id} travelOrder={travelOrder}/>
                                })
                            }

                            {travelOrders.length === 0 ? <div className='has-text-centered py-6'><p className='is-size-4 has-text-weight-semibold'>Empty</p><p>No travel orders</p></div> : ""}
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default TravelOrders;