import Button from "./button";
import {useNavigate} from 'react-router-dom';

const EmployeeItem = ({user}) => {
    const { firstName, surname, middleName, emailAddress, created, id, files } = user;
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate('/pds/' + id);
    }

    return (
        <article className="media with-background-white">
            <figure className="media-left">
                <p className="image is-64x64">
                <img className="is-rounded" src={files ? files.photoFileUrl : "https://bulma.io/images/placeholders/128x128.png"}/>
                </p>
            </figure>
            <div className="media-content">
                <div className="content">
                <p>
                    <strong>{middleName ? surname + ", " + firstName + " " + middleName.substring(0, 1) + "." : surname + ", " + firstName}</strong>
                    <br/>
                    {emailAddress}
                </p>
                </div>
            </div>
            <div className="media-right">
                <Button type="button" onClick={onClickHandler}>View</Button>
            </div>
        </article>
    )
}

export default EmployeeItem;